<template>
  <div>
    <div class="lg:hidden">
      <div class="">
        <div>
          <BSpinner v-if="isCompressing || galleryResponse.isLoading" />

          <ProjectImageCrop
            v-if="cropImage"
            :file="file"
            @save="handleSave"
            @cancel="handleCancel"
          />

          <ProjectImageCrop
            v-else-if="cropCoverImage"
            :file="coverFile"
            @save="handleCoverSave"
            @cancel="handleCoverCancel"
            type="cover"
          />

          <div class="px-4 py-4 pt-8 min-h-screen flex flex-col" v-else>
            <header
              class="font-semibold text-lg flex text-text-alternate-1 pr-6"
            >
              <button @click="handlePrev">
                <LeftArrowIcon class="h-6 fill-current" />
              </button>
              <h2 class="mx-auto">Create Permanent Fundraiser</h2>
            </header>
            <form class="my-8" @submit.prevent="handleSubmit">
              <div
                class="
                  flex flex-col
                  my-3
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label>Cover Image</label>
                  <BToolTipBtn :details="infoData.coverImage" />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    my-2
                    overflow-hidden
                    w-full
                  "
                >
                  <AttachmentIcon class="mx-5 h-6.5 fill-current" />
                  <label
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      text-text-alternate-3
                      opacity-70
                    "
                    >Choose image (jpg, jpeg, png) (Max. 1MB)
                    <input
                      type="file"
                      hidden
                      accept="image/png,image/jpeg,image/jpg"
                      @change="handleFileInputCover"
                      :disabled="coverImage"
                    />
                  </label>
                </div>
                <p class="font-medium text-text-alternate-3 text-xs">
                  Add your preferred cover photo
                </p>
              </div>
              <div class="overflow-x-auto py-1" v-if="coverImage">
                <div class="inline-flex gap-3">
                  <div class="w-72 relative rounded-md overflow-hidden">
                    <button
                      class="
                        text-2xl
                        bg-bg-primary
                        rounded-full
                        overflow-hidden
                        h-5
                        w-5
                        absolute
                        z-10
                        right-4
                        top-4
                      "
                      @click.prevent="removeCoverImage()"
                    >
                      <span class="inline-block transform -translate-y-1">
                        &times;
                      </span>
                    </button>
                    <img :src="coverImage" alt="image" />
                  </div>
                </div>
              </div>
              <div
                class="
                  flex flex-col
                  my-3
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label>Add Gallery Images</label>
                  <BToolTipBtn :details="infoData.images" />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    my-2
                    overflow-hidden
                    w-full
                  "
                >
                  <AttachmentIcon class="mx-5 h-6.5 fill-current" />
                  <label
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      text-text-alternate-3
                      opacity-70
                    "
                    >Choose image (jpg, jpeg, png) (Max. 1MB)
                    <input
                      type="file"
                      hidden
                      accept="image/png,image/jpeg,image/jpg"
                      @change="handleFileInput"
                    />
                  </label>
                </div>
                <p class="font-medium text-text-alternate-3 text-xs">
                  Add at least 4 image (You may add as many as possible)
                </p>
              </div>
              <div class="overflow-x-auto py-1" v-if="images.length">
                <div class="inline-flex gap-3">
                  <div
                    class="w-72 relative rounded-md overflow-hidden"
                    v-for="(link, index) of images"
                    :key="index"
                  >
                    <button
                      class="
                        text-2xl
                        bg-bg-primary
                        rounded-full
                        overflow-hidden
                        h-5
                        w-5
                        absolute
                        z-10
                        right-4
                        top-4
                      "
                      @click.prevent="removeImage(index)"
                    >
                      <span class="inline-block transform -translate-y-1">
                        &times;
                      </span>
                    </button>
                    <img :src="link" alt="image" />
                  </div>
                </div>
              </div>
              <div
                class="
                  flex flex-col
                  my-6
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label>Organisation Website</label>
                  <BToolTipBtn :details="infoData.orgWebsite" />
                </div>
                <input
                  class="
                    border
                    rounded
                    border-border-primary
                    mt-2
                    mb-1
                    p-4
                    text-sm
                    font-medium
                    focus:outline-none
                    input-shadow
                  "
                  placeholder="https://"
                  type="text"
                  v-model.trim="organisationWebsite"
                  maxlength="50"
                />
              </div>

              <div
                class="
                  flex flex-col
                  my-5
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label
                    >Organisation Social Media
                    <span class="font-medium">(optional)</span></label
                  >
                  <BToolTipBtn :details="infoData.socials" />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                    mt-2
                  "
                >
                  <FBIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="https://"
                    v-model.trim="facebook"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <IGIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="https://"
                    v-model.trim="instagram"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <TWIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="https://"
                    v-model.trim="twitter"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <YTIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="https://"
                    v-model.trim="youtube"
                  />
                </div>
              </div>
              <div
                class="
                  flex flex-col
                  my-5
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label>Add Contact Information </label>
                  <BToolTipBtn :details="infoData.contact" />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                    mt-2
                  "
                >
                  <PlaceIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="Address"
                    v-model.trim="contactAddress"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <MailIcon class="mx-3.5 h-6.5" />

                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="Email"
                    v-model.trim="contactEmail"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <PhoneIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="number"
                    placeholder="Phone number"
                    v-model.trim="contactPhoneNumber"
                  />
                </div>
              </div>
              <div class="flex">
                <button
                  @click="handlePrev"
                  type="button"
                  class="text-text-primary text-left font-bold text-sm w-2/3"
                >
                  Previous
                </button>
                <button
                  class="
                    bg-btn-bg-primary
                    text-btn-text-primary
                    rounded
                    w-full
                    py-4
                    mt-2
                    font-bold
                    text-sm
                    xs:text-base
                  "
                  type="submit"
                  :disabled="!isFilled"
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24">
        <div>
          <BSpinner v-if="isCompressing || galleryResponse.isLoading" />

          <ProjectImageCrop
            v-if="cropImage"
            :file="file"
            @save="handleSave"
            @cancel="handleCancel"
          />

          <ProjectImageCrop
            v-else-if="cropCoverImage"
            :file="coverFile"
            @save="handleCoverSave"
            @cancel="handleCoverCancel" type="cover"
          />

          <div class="px-26 py-4 pt-8 min-h-screen flex flex-col" v-else>
            <header
              class="font-semibold text-lg flex text-text-alternate-1 pr-6"
            >
              <button @click="handlePrev">
                <LeftArrowIcon class="h-6 fill-current" />
              </button>
              <h2 class="mx-auto">Create Permanent Fundraiser</h2>
            </header>
            <form class="my-8" @submit.prevent="handleSubmit">
              <div
                class="
                  flex flex-col
                  my-3
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label>Cover Image</label>
                  <BToolTipBtn :details="infoData.coverImage" />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    my-2
                    overflow-hidden
                    w-full
                  "
                >
                  <AttachmentIcon class="mx-5 h-6.5 fill-current" />
                  <label
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      text-text-alternate-3
                      opacity-70
                    "
                    >Choose image (jpg, jpeg, png) (Max. 1MB)
                    <input
                      type="file"
                      hidden
                      accept="image/png,image/jpeg,image/jpg"
                      @change="handleFileInputCover"
                      :disabled="coverImage"
                    />
                  </label>
                </div>
                <p class="font-medium text-text-alternate-3 text-xs">
                  Add your preferred cover photo
                </p>
              </div>
              <div class="overflow-x-auto py-1" v-if="coverImage">
                <div class="inline-flex gap-3">
                  <div class="w-72 relative rounded-md overflow-hidden">
                    <button
                      class="
                        text-2xl
                        bg-bg-primary
                        rounded-full
                        overflow-hidden
                        h-5
                        w-5
                        absolute
                        z-10
                        right-4
                        top-4
                      "
                      @click.prevent="removeCoverImage()"
                    >
                      <span class="inline-block transform -translate-y-1">
                        &times;
                      </span>
                    </button>
                    <img :src="coverImage" alt="image" />
                  </div>
                </div>
              </div>
              <div
                class="
                  flex flex-col
                  my-3
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label>Add Gallery Images</label>
                  <BToolTipBtn :details="infoData.images" />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    my-2
                    overflow-hidden
                    w-full
                  "
                >
                  <AttachmentIcon class="mx-5 h-6.5 fill-current" />
                  <label
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      text-text-alternate-3
                      opacity-70
                    "
                    >Choose image (jpg, jpeg, png) (Max. 1MB)
                    <input
                      type="file"
                      hidden
                      accept="image/png,image/jpeg,image/jpg"
                      @change="handleFileInput"
                    />
                  </label>
                </div>
                <p class="font-medium text-text-alternate-3 text-xs">
                  Add at least 3 image (You may add as many as possible)
                </p>
              </div>
              <div class="overflow-x-auto py-1" v-if="images.length">
                <div class="inline-flex gap-3">
                  <div
                    class="w-72 relative rounded-md overflow-hidden"
                    v-for="(link, index) of images"
                    :key="index"
                  >
                    <button
                      class="
                        text-2xl
                        bg-bg-primary
                        rounded-full
                        overflow-hidden
                        h-5
                        w-5
                        absolute
                        z-10
                        right-4
                        top-4
                      "
                      @click.prevent="removeImage(index)"
                    >
                      <span class="inline-block transform -translate-y-1">
                        &times;
                      </span>
                    </button>
                    <img :src="link" alt="image" />
                  </div>
                </div>
              </div>
              <div
                class="
                  flex flex-col
                  my-6
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label>Organisation Website</label>
                  <BToolTipBtn :details="infoData.orgWebsite" />
                </div>
                <input
                  class="
                    border
                    rounded
                    border-border-primary
                    mt-2
                    mb-1
                    p-4
                    text-sm
                    font-medium
                    focus:outline-none
                    input-shadow
                  "
                  placeholder="https://"
                  type="text"
                  v-model.trim="organisationWebsite"
                  maxlength="50"
                />
              </div>

              <div
                class="
                  flex flex-col
                  my-5
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label
                    >Organisation Social Media
                    <span class="font-medium">(optional)</span></label
                  >
                  <BToolTipBtn :details="infoData.socials" />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                    mt-2
                  "
                >
                  <FBIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="https://"
                    v-model.trim="facebook"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <IGIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="https://"
                    v-model.trim="instagram"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <TWIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="https://"
                    v-model.trim="twitter"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <YTIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="https://"
                    v-model.trim="youtube"
                  />
                </div>
              </div>
              <div
                class="
                  flex flex-col
                  my-5
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label>Add Contact Information </label>
                  <BToolTipBtn :details="infoData.contact" />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                    mt-2
                  "
                >
                  <PlaceIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="Address"
                    v-model.trim="contactAddress"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <MailIcon class="mx-3.5 h-6.5" />

                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="Email"
                    v-model.trim="contactEmail"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <PhoneIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="number"
                    placeholder="Phone number"
                    v-model.trim="contactPhoneNumber"
                  />
                </div>
              </div>
              <div class="flex">
                <button
                  @click="handlePrev"
                  type="button"
                  class="text-text-primary text-left font-bold text-sm w-2/3"
                >
                  Previous
                </button>
                <button
                  class="
                    bg-btn-bg-primary
                    text-btn-text-primary
                    rounded
                    w-full
                    py-4
                    mt-2
                    font-bold
                    text-sm
                    xs:text-base
                  "
                  type="submit"
                  :disabled="!isFilled"
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref, computed, watch } from 'vue';
// import BToggle from '@/components/ui/BToggle';
import ProjectImageCrop from '../ProjectImageCrop';
// import { getProjectData, getYouTubeVideoId } from '@/utils';
import { useApi } from '@/cmp-functions/useApi';
import { /*postFundraiser,*/ postAnImage, searchUsers } from '@/services/api';
// import { useStore } from 'vuex';
import BSpinner from '@/components/ui/BSpinner';
import randomColor from 'randomcolor';
import imageCompression from 'browser-image-compression';
import infoData from '@/data/info.js';
import BToolTipBtn from '@/components/ui/BToolTipBtn';
import { useToast } from 'vue-toastification';

const data = {
  images: [],
  organisationWebsite: '',
  coverImage: null,
  canComment: false,
  rewards: [],
  isPrivate: false,
  isOrganiserBeneficiary: true,
};
const notRequired = {
  facebook: '',
  instagram: '',
  twitter: '',
  youtube: '',
  tiktok: '',
  youTubeLink: '',
  contactAddress: '',
  contactEmail: '',
  contactPhoneNumber: '',
  beneficiary: { fullname: '', link: '' },
};
const recurringData = {
  monthlyDonations: [
    {
      donationtype: 'SILVER',
      amount: 5,
    },
    {
      donationtype: 'GOLD',
      amount: 10,
    },
    {
      donationtype: 'DIAMOND',
      amount: 50,
    },
  ],
};

export default {
  name: 'ProjectDetailsTwo',

  emits: ['next', 'prev'],

  props: ['project'],

  components: {
    ProjectImageCrop,
    // BToggle,
    BSpinner,
    BToolTipBtn,
  },

  setup(props, { emit }) {
    const cropImage = ref(false);
    const cropCoverImage = ref(false);
    // const store = useStore();
    // const authUser = store.state.auth.authData;
    const isCompressing = ref(false);
    const imageErrorMsg = ref('');
    const toast = useToast();

    const toggleCropImage = () => {
      cropImage.value = !cropImage.value;
    };

    const toggleCropCoverImage = () => {
      cropCoverImage.value = !cropCoverImage.value;
    };

    const initFormInputs = {
      ...data,
      ...recurringData,
    };

    const formInputs = reactive({
      ...Object.keys(initFormInputs).reduce(
        (acc, next) => ({
          ...acc,
          [next]:
            props.project[next] !== undefined
              ? props.project[next]
              : initFormInputs[next],
        }),
        {}
      ),
      ...Object.keys(notRequired).reduce(
        (acc, next) => ({
          ...acc,
          [next]:
            props.project[next] !== undefined
              ? props.project[next]
              : notRequired[next],
        }),
        {}
      ),
    });
    const blessingForm = reactive({
      amount: '',
      reward: '',
      file: '',
      coverFile: null,
      memberUsername: '',
      canEdit: false,
      isRewarding: props.project.rewards?.length > 0,
      silverDonor: 5,
      goldDonor: 10,
      diamondDonor: 50,
    });

    watch(blessingForm, ({ isRewarding }) => {
      if (!isRewarding) {
        formInputs.rewards = [];
      }
    });

    // const [fundraiserResponse, createFundraiser] = useApi(postFundraiser);
    const [galleryResponse, postGallery] = useApi(postAnImage);
    const [coverResponse, postCover] = useApi(postAnImage);

    // watch(fundraiserResponse, ({ isSuccess, data }) => {
    //   if (isSuccess) {
    //     emit('next', { ...formInputs, id: data.id });
    //   }
    // });

    watch(galleryResponse, ({ isSuccess, isFailure, data, error }) => {
      if (isSuccess) {
        formInputs.images.push(data.imageurl);
        blessingForm.file = null;
        toggleCropImage();
      } else if (isFailure) {
        toast.error(error);
      }
    });

    watch(coverResponse, ({ isSuccess, isFailure, data, error }) => {
      if (isSuccess) {
        formInputs.coverImage = data.imageurl;
        blessingForm.file = null;
        toggleCropCoverImage();
      } else if (isFailure) {
        toast.error(error);
      }
    });

    const handleSave = async (image) => {
      const imageSize = Math.round(
        Buffer.from(image.substring(image.indexOf(',') + 1)).length / 1e3
      );
      if (imageSize > 1000) {
        toast.error('Image size is too large, Maximum image size should be 1MB.');
      } else {
        const { name } = blessingForm.file;
        postGallery({ file: { name, mimetype: 'image/jpeg', base64: image } });
      }
    };

    const handleCoverSave = async (image) => {
      const imageSize = Math.round(
        Buffer.from(image.substring(image.indexOf(',') + 1)).length / 1e3
      );
      if (imageSize > 1000) {
        toast.error('Image size is too large, Maximum image size should be 1MB.');
      } else {
        const { name } = blessingForm.coverFile;
        postCover({ file: { name, mimetype: 'image/jpeg', base64: image } });
      }
    };

    // const handleSubmit = () => {
    //   const payload = getProjectData(
    //     { ...props.project, ...formInputs },
    //     authUser.id
    //   );
    //   createFundraiser(payload);
    // };

    const handleSubmit = () => {
      // const categoryId = categories.data.find(
      //   (c) => c.categoryname === formInputs.category
      // )?.id;
      emit('next', { ...formInputs });
    };

    const handlePrev = () => {
      emit('prev', formInputs);
    };

    const handleMonthlyButton = () => {
      const { silverDonor, goldDonor, diamondDonor, canEdit } = blessingForm;
      if (canEdit) {
        formInputs.monthlyDonations = [
          { donationtype: 'SILVER', amount: silverDonor },
          { donationtype: 'GOLD', amount: goldDonor },
          { donationtype: 'DIAMOND', amount: diamondDonor },
        ];
      }

      blessingForm.canEdit = !blessingForm.canEdit;
    };

    const validateFileInput = async (target) => {
      let file = target.files[0];
      let image = new Image();
      let reader = new FileReader();
      let width, height;
      reader.onload = async (e) => {
        image.src = e.target.result;

        image.onload = () => {
          width = image.width;
          height = image.height;

          if (image.width < 630 || image.height < 1200) {
            toast.error(
              'Image resolution is too low, image size should be atleast 630 x 1200px'
            );
            imageErrorMsg.value =
              'Image resolution is too low, image size should be atleast 630 x 1200px';
          } else {
            imageErrorMsg.value = '';
          }
        };
      };

      reader.readAsDataURL(file);

      console.log('Image Width: ', width);
      console.log('Image Height: ', height);
      console.log('Image error: ', imageErrorMsg.value);

      if (imageErrorMsg.value == '') {
        return true;
      } else {
        return false;
      }
    };

    const handleFileInput = async ({ target }) => {
      try {
        // console.log(imageType);
        isCompressing.value = true;
        let file = target.files[0];
        blessingForm.file = await imageCompression(file, { maxSizeMB: 0.05 });
        toggleCropImage();
      } catch (error) {
        console.log(error);
      } finally {
        isCompressing.value = false;
      }
    };

    const handleFileInputCover = async ({ target }) => {
      try {
        // console.log(imageType);
        isCompressing.value = true;
        let file = target.files[0];
        blessingForm.coverFile = await imageCompression(file, {
          maxSizeMB: 0.05,
        });
        toggleCropCoverImage();
      } catch (error) {
        console.log(error);
      } finally {
        isCompressing.value = false;
      }
    };

    const handleReward = () => {
      const { amount, reward } = blessingForm;
      formInputs.rewards = [...formInputs.rewards, { amount, reward }];
      blessingForm.amount = '';
      blessingForm.reward = '';
    };

    const removeReward = (index) => {
      formInputs.rewards = formInputs.rewards.filter(
        (rwd, ind) => ind !== index
      );
    };

    const removeMember = (index) => {
      formInputs.members = formInputs.members.filter((_, ind) => index !== ind);
    };

    const addMember = (userdetail) => {
      if (alreadySelected(userdetail.userid)) return;

      formInputs.members = [
        ...formInputs.members,
        { userdetail, memberType: 'OBSERVER' },
      ];
      blessingForm.memberUsername = '';
    };

    const handleCancel = () => {
      blessingForm.file = null;
      toggleCropImage();
    };

    const handleCoverCancel = () => {
      blessingForm.coverFile = null;
      toggleCropCoverImage();
    };

    const removeImage = (index) => {
      formInputs.images = formInputs.images.filter((img, ind) => ind !== index);
    };

    const removeCoverImage = () => {
      formInputs.coverImage = null;
    };

    const isFilled = computed(
      () =>
        Object.keys(initFormInputs).every(
          (key) =>
            formInputs[key] !== null &&
            formInputs[key] !== '' &&
            formInputs.images.length > 0
        ) &&
        !blessingForm.canEdit &&
        (blessingForm.isRewarding ? formInputs.rewards.length > 0 : true) &&
        (formInputs.isOrganiserBeneficiary ||
          (formInputs.beneficiary.fullname && formInputs.beneficiary.link))
    );

    const alreadySelected = (id) => {
      return formInputs.members.some(
        ({ userdetail }) => userdetail.userid == id
      );
    };

    // const embededYoutubeId = computed(() =>
    //   getYouTubeVideoId(formInputs.youTubeLink)
    // );

    return {
      ...toRefs(formInputs),
      ...toRefs(blessingForm),
      // embededYoutubeId,
      isCompressing,
      alreadySelected,
      cropImage,
      cropCoverImage,
      toggleCropImage,
      toggleCropCoverImage,
      searchUsers,
      randomColor,
      isFilled,
      removeImage,
      removeCoverImage,
      handleSubmit,
      handleMonthlyButton,
      // fundraiserResponse,
      galleryResponse,
      coverResponse,
      handleCoverSave,
      handleCoverCancel,
      handleSave,
      handleCancel,
      removeReward,
      removeMember,
      addMember,
      handleReward,
      handleFileInput,
      handleFileInputCover,
      handlePrev,
      imageErrorMsg,
      infoData,
      validateFileInput,
    };
  },
};
</script>
