<template>
  <div>
    <div class="lg:hidden">
      <div class="">
        <div>
          <BSpinner
            v-if="
              isCompressing ||
              fundraiserResponse.isLoading ||
              impactStoryImageResponse.isLoading
            "
          />

          <ProjectImageCrop
            v-if="cropImage"
            :file="file"
            @save="handleSave"
            @cancel="handleCancel"
          />
          <ProjectImageCrop
            v-else-if="cropImageTeamMember"
            :file="file"
            type="profilepic"
            @save="handleSaveTeamMember"
            @cancel="handleCancelTeamMember"
          />

          <div class="px-4 py-4 pt-8 min-h-screen flex flex-col" v-else>
            <header
              class="font-semibold text-lg flex text-text-alternate-1 pr-6"
            >
              <button @click="handlePrev">
                <LeftArrowIcon class="h-6 fill-current" />
              </button>
              <h2 class="mx-auto">Create Permanent Fundraiser</h2>
            </header>

            <form class="my-8" @submit.prevent="handleSubmit">
              <div
                class="flex flex-col mt-6 text-text-primary font-semibold text-sm"
              >
                <div class="flex">
                  <label>Impact Story (Optional)</label>
                  <BToolTipBtn :details="infoData.impactStory" />
                </div>
                <input
                  class="border rounded border-border-primary mt-2 mb- p-4 text-sm font-medium focus:outline-none input-shadow"
                  placeholder="Title"
                  type="text"
                  v-model.trim="impactStoryTitle"
                  maxlength="50"
                />
              </div>

              <div
                class="flex flex-col my-3 text-text-primary font-semibold text-sm"
              >
                <div
                  class="border rounded border-border-primary flex items-center overflow-hidden w-full"
                >
                  <AttachmentIcon class="mx-5 h-6.5 fill-current" />
                  <label
                    class="p-4 w-full border-l border-border-primary text-sm font-semibold text-text-alternate-3 opacity-70"
                    >Choose image (jpg, jpeg, png) (Max. 1MB)
                    <input
                      type="file"
                      hidden
                      accept="image/png,image/jpeg,image/jpg"
                      @change="handleFileInput"
                    />
                  </label>
                </div>
                <p class="font-medium text-text-alternate-3 text-xs">
                  Add an image for the impact story
                </p>
              </div>
              <div class="overflow-x-auto py-1" v-if="impactStoryImage">
                <div class="inline-flex gap-3">
                  <div class="w-72 relative rounded-md overflow-hidden">
                    <button
                      class="text-2xl bg-bg-primary rounded-full overflow-hidden h-5 w-5 absolute z-10 right-4 top-4"
                      @click.prevent="removeImpactStoryImage()"
                    >
                      <span class="inline-block transform -translate-y-1">
                        &times;
                      </span>
                    </button>
                    <img :src="impactStoryImage" alt="image" />
                  </div>
                </div>
              </div>
              <div
                class="flex relative flex-col mt-3 text-text-primary font-semibold text-sm"
              >
                <!-- <label class="">Date</label> -->

                <textarea
                  class="border rounded border-border-primary p-3.75 text-sm font-medium focus:outline-none input-shadow"
                  v-model.trim="impactStoryDescription"
                  rows="4"
                  placeholder="Description"
                />
              </div>
              <button
                @click="handleSaveImpactStory"
                type="button"
                class="bg-btn-bg-alternate-1 h-12 w-full rounded mt-3 text-sm font-bold text-text-alternate-10"
                :disabled="!isImpactStoryFilled"
              >
                Add Impact Story
              </button>

              <div
                class="border border-border-alternate-6 mt-6 rounded-lg p-4"
                v-for="(item, index) in impactStories"
                :key="index"
              >
                <CloseIcon
                  class="float-right cursor-pointer"
                  @click="removeImpactStory(index)"
                />

                <div class="flex items-center">
                  <div>
                    <img
                      :src="item.image"
                      class="object-cover w-32 h-20"
                      alt=""
                    />
                  </div>
                  <div class="ml-4">
                    <p class="font-semibold text-sm text-text-alternate-1">
                      {{ item.title }}
                    </p>
                  </div>
                </div>
                <p class="mt-3 text-sm font-medium text-text-alternate-1">
                  {{ item.description }}
                </p>
              </div>

              <hr class="my-6 border-border-primary border border-t-0" />

              <div
                class="flex flex-col mt-6 text-text-primary font-semibold text-sm"
              >
                <div class="flex">
                  <label>Team Members (Optional)</label>
                  <BToolTipBtn :details="infoData.team" />
                </div>
                <input
                  class="border rounded border-border-primary mt-2 mb- p-4 text-sm font-medium focus:outline-none input-shadow"
                  placeholder="Name"
                  type="text"
                  v-model.trim="teamMemberName"
                  maxlength="50"
                />
              </div>
              <div
                class="flex relative flex-col mt-3 text-text-primary font-semibold text-sm"
              >
                <!-- <label class="">Date</label> -->

                <input
                  class="border rounded border-border-primary p-3.75 text-sm font-medium focus:outline-none input-shadow"
                  v-model="teamMemberRole"
                  placeholder="Role"
                />
              </div>
              <div
                class="flex flex-col my-3 text-text-primary font-semibold text-sm"
              >
                <div
                  class="border rounded border-border-primary flex items-center overflow-hidden w-full"
                >
                  <AttachmentIcon class="mx-5 h-6.5 fill-current" />
                  <label
                    class="p-4 w-full border-l border-border-primary text-sm font-semibold text-text-alternate-3 opacity-70"
                    >Choose image (jpg, jpeg, png) (Max. 1MB)
                    <input
                      type="file"
                      hidden
                      accept="image/png,image/jpeg,image/jpg"
                      @change="handleFileInputTeamMember"
                    />
                  </label>
                </div>
                <p class="font-medium text-text-alternate-3 text-xs">
                  Add an image of the team member
                </p>
              </div>
              <div class="overflow-x-auto py-1" v-if="teamMemberImage">
                <div class="inline-flex gap-3">
                  <div class="w-72 relative rounded-md overflow-hidden">
                    <button
                      class="text-2xl bg-bg-primary rounded-full overflow-hidden h-5 w-5 absolute z-10 right-4 top-4 cursor-pointer"
                      @click.prevent="removeTeamMemberImage()"
                    >
                      <span class="inline-block transform -translate-y-1">
                        &times;
                      </span>
                    </button>
                    <img :src="teamMemberImage" alt="image" />
                  </div>
                </div>
              </div>
              <div
                class="flex relative flex-col mt-3 text-text-primary font-semibold text-sm"
              >
                <!-- <label class="">Date</label> -->

                <textarea
                  class="border rounded border-border-primary p-3.75 text-sm font-medium focus:outline-none input-shadow"
                  v-model="teamMemberDescription"
                  rows="4"
                  placeholder="Description"
                />
              </div>
              <button
                :disabled="!isTeamMemberFilled"
                @click="handleAddTeamMember"
                type="button"
                class="bg-btn-bg-alternate-1 h-12 w-full rounded mt-3 text-sm font-bold text-text-alternate-10"
              >
                Add Member
              </button>

              <div
                class="border border-border-alternate-6 mt-6 rounded-lg p-4"
                v-for="(team, index) in teamMembers"
                :key="index"
              >
                <CloseIcon
                  class="float-right"
                  @click="removeTeamMember(index)"
                />

                <div class="flex items-center">
                  <div>
                    <img
                      :src="team.image"
                      class="object-cover w-20 h-20 rounded-full"
                      alt=""
                    />
                  </div>
                  <div class="ml-4">
                    <p class="font-semibold text-sm text-text-alternate-1">
                      {{ team.name }}
                    </p>
                    <p class="font-semibold text-text-alternate-3 text-xs mt-3">
                      {{ team.role }}
                    </p>
                  </div>
                </div>
                <p class="mt-3 text-sm font-medium text-text-alternate-1">
                  {{ team.description }}
                </p>
              </div>

              <hr class="my-6 border-border-primary border border-t-0" />

              <div
                class="rounded bg-bg-alternate-3 p-4 my-4 relative text-text-primary"
              >
                <div class="flex justify-between">
                  <h3 class="font-semibold mb-2 text-sm xs:text-base">
                    Make Private
                  </h3>
                  <BToggle v-model="isPrivate" />
                </div>
                <p class="text-2xs xs:text-xs">
                  Hide your fundraiser from the public. You can still share the
                  link with anyone to recieve donations.
                </p>
              </div>

              <!-- <div
                class="
                  rounded
                  bg-bg-alternate-3
                  p-4
                  my-4
                  relative
                  text-text-primary
                "
              >
                <div class="flex justify-between">
                  <h3 class="font-semibold mb-2 text-sm xs:text-base">
                    Allow “Make A Wish”
                  </h3>
                  <BToggle v-model="allowMakeAWish" />
                </div>
                <p class="text-2xs xs:text-xs">
                  Turn on/off the ability for users to use Make A wish feature.
                  Learn more about Make A Wish here.
                </p>
              </div> -->

              <div
                class="rounded bg-bg-alternate-3 p-4 my-4 relative text-text-primary"
              >
                <div class="flex justify-between">
                  <h3 class="font-semibold mb-2 text-sm xs:text-base">
                    Allow Promote and Earn
                  </h3>
                  <BToggle v-model="allowPromoteAndEarn" />
                </div>
                <p class="text-2xs xs:text-xs">
                  Give back to people who donate to your fundraiser as a show of
                  appreciation and encouragement.
                </p>
              </div>

              <div v-if="allowPromoteAndEarn">
                <div
                  class="flex flex-col my-3 text-text-primary font-semibold text-sm"
                >
                  <label>Commission percentage (%)</label>
                  <input
                    class="border rounded border-border-primary my-6 text-sm font-medium slider h-1 focus:outline-none"
                    type="range"
                    placeholder="20"
                    v-model.number="promoteAndEarnCommPercent"
                    min="20"
                    max="50"
                    pattern="\d*"
                    inputmode="decimal"
                  />
                  <span
                    class="rounded border border-border-primary px-4 py-3.5 min-w-36 max-w-min font-medium mb-3"
                  >
                    {{ promoteAndEarnCommPercent }}%
                  </span>
                  <p class="font-medium text-text-alternate-3 text-xs">
                    Specify your Promote and Earn Commission percentage, should
                    be between 20 and 50%
                  </p>
                </div>
              </div>

              <div
                class="rounded bg-bg-alternate-3 p-4 my-4 relative text-text-primary"
              >
                <div class="flex justify-between items-center mb-4">
                  <h3 class="font-semibold text-sm xs:text-base">
                    Monthly Donation
                  </h3>
                  <button
                    class="focus:outline-none border border-border-alternate-4 text-text-alternate-4 font-semibold rounded-full w-14 text-xs py-0.5"
                    @click.prevent="handleMonthlyButton"
                  >
                    {{ canEdit ? "Save" : "Edit" }}
                  </button>
                </div>
                <p class="text-2xs xs:text-xs mt-2 mb-8">
                  Solicit monthly donations from your donors by specifying
                  subscription amounts. E.g. $5/Month
                </p>
                <div
                  class="flex flex-col my-3 text-text-primary font-semibold text-sm"
                >
                  <label class="text-xs">Silver Donor</label>
                  <div class="flex my-2 w-full items-center">
                    <input
                      :class="[
                        'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                        { 'bg-bg-alternate-3': !canEdit },
                      ]"
                      :readonly="!canEdit"
                      type="number"
                      v-model.number="silverDonor"
                      pattern="\d*"
                      inputmode="decimal"
                      min="0"
                    />
                    <p class="ml-2 whitespace-nowrap">Per Month</p>
                  </div>
                </div>
                <div
                  class="flex flex-col my-3 text-text-primary font-semibold text-sm"
                >
                  <label class="text-xs">Gold Donor</label>
                  <div class="flex my-2 w-full items-center">
                    <input
                      :class="[
                        'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                        { 'bg-bg-alternate-3': !canEdit },
                      ]"
                      :readonly="!canEdit"
                      type="number"
                      v-model.number="goldDonor"
                      pattern="\d*"
                      inputmode="decimal"
                      min="0"
                    />
                    <p class="ml-2 whitespace-nowrap">Per Month</p>
                  </div>
                </div>
                <div
                  class="flex flex-col my-3 text-text-primary font-semibold text-sm"
                >
                  <label class="text-xs">Diamond Donor</label>
                  <div class="flex my-2 w-full items-center">
                    <input
                      :class="[
                        'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                        { 'bg-bg-alternate-3': !canEdit },
                      ]"
                      :readonly="!canEdit"
                      type="number"
                      v-model.number="diamondDonor"
                      pattern="\d*"
                      inputmode="decimal"
                      min="0"
                    />
                    <p class="ml-2 whitespace-nowrap">Per Month</p>
                  </div>
                </div>
              </div>

              <div class="flex">
                <button
                  @click="handlePrev"
                  type="button"
                  class="text-text-primary text-left font-bold text-sm w-2/3"
                >
                  Previous
                </button>
                <button
                  class="bg-btn-bg-primary text-btn-text-primary rounded w-full py-4 mt-2 font-bold text-sm xs:text-base"
                  type="submit"
                  :disabled="!isFilled"
                >
                  Create Fundraiser
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24">
        <div>
          <BSpinner
            v-if="
              isCompressing ||
              fundraiserResponse.isLoading ||
              impactStoryImageResponse.isLoading
            "
          />

          <ProjectImageCrop
            v-if="cropImage"
            :file="file"
            @save="handleSave"
            @cancel="handleCancel"
          />
          <ProjectImageCrop
            v-else-if="cropImageTeamMember"
            :file="file"
            type="profilepic"
            @save="handleSaveTeamMember"
            @cancel="handleCancelTeamMember"
          />

          <div class="px-26 py-4 pt-8 min-h-screen flex flex-col" v-else>
            <header
              class="font-semibold text-lg flex text-text-alternate-1 pr-6"
            >
              <button @click="handlePrev">
                <LeftArrowIcon class="h-6 fill-current" />
              </button>
              <h2 class="mx-auto">Create Permanent Fundraiser</h2>
            </header>

            <form class="my-8" @submit.prevent="handleSubmit">
              <div
                class="flex flex-col mt-6 text-text-primary font-semibold text-sm"
              >
                <div class="flex">
                  <label>Impact Story (Optional)</label>
                  <BToolTipBtn :details="infoData.impactStory" />
                </div>
                <input
                  class="border rounded border-border-primary mt-2 mb- p-4 text-sm font-medium focus:outline-none input-shadow"
                  placeholder="Title"
                  type="text"
                  v-model.trim="impactStoryTitle"
                  maxlength="50"
                />
              </div>

              <div
                class="flex flex-col my-3 text-text-primary font-semibold text-sm"
              >
                <div
                  class="border rounded border-border-primary flex items-center overflow-hidden w-full"
                >
                  <AttachmentIcon class="mx-5 h-6.5 fill-current" />
                  <label
                    class="p-4 w-full border-l border-border-primary text-sm font-semibold text-text-alternate-3 opacity-70"
                    >Choose image (jpg, jpeg, png) (Max. 1MB)
                    <input
                      type="file"
                      hidden
                      accept="image/png,image/jpeg,image/jpg"
                      @change="handleFileInput"
                    />
                  </label>
                </div>
                <p class="font-medium text-text-alternate-3 text-xs">
                  Add an image for the impact story
                </p>
              </div>
              <div class="overflow-x-auto py-1" v-if="impactStoryImage">
                <div class="inline-flex gap-3">
                  <div class="w-72 relative rounded-md overflow-hidden">
                    <button
                      class="text-2xl bg-bg-primary rounded-full overflow-hidden h-5 w-5 absolute z-10 right-4 top-4"
                      @click.prevent="removeImpactStoryImage()"
                    >
                      <span class="inline-block transform -translate-y-1">
                        &times;
                      </span>
                    </button>
                    <img :src="impactStoryImage" alt="image" />
                  </div>
                </div>
              </div>
              <div
                class="flex relative flex-col mt-3 text-text-primary font-semibold text-sm"
              >
                <!-- <label class="">Date</label> -->

                <textarea
                  class="border rounded border-border-primary p-3.75 text-sm font-medium focus:outline-none input-shadow"
                  v-model.trim="impactStoryDescription"
                  rows="4"
                  placeholder="Description"
                />
              </div>
              <button
                @click="handleSaveImpactStory"
                type="button"
                class="bg-btn-bg-alternate-1 h-12 w-full rounded mt-3 text-sm font-bold text-text-alternate-10"
                :disabled="!isImpactStoryFilled"
              >
                Add Impact Story
              </button>

              <div
                class="border border-border-alternate-6 mt-6 rounded-lg p-4"
                v-for="(item, index) in impactStories"
                :key="index"
              >
                <CloseIcon
                  class="float-right cursor-pointer"
                  @click="removeImpactStory(index)"
                />

                <div class="flex items-center">
                  <div>
                    <img
                      :src="item.image"
                      class="object-cover w-32 h-20"
                      alt=""
                    />
                  </div>
                  <div class="ml-4">
                    <p class="font-semibold text-sm text-text-alternate-1">
                      {{ item.title }}
                    </p>
                  </div>
                </div>
                <p class="mt-3 text-sm font-medium text-text-alternate-1">
                  {{ item.description }}
                </p>
              </div>

              <hr class="my-6 border-border-primary border border-t-0" />

              <div
                class="flex flex-col mt-6 text-text-primary font-semibold text-sm"
              >
                <div class="flex">
                  <label>Team Members (Optional)</label>
                  <BToolTipBtn :details="infoData.team" />
                </div>
                <input
                  class="border rounded border-border-primary mt-2 mb- p-4 text-sm font-medium focus:outline-none input-shadow"
                  placeholder="Name"
                  type="text"
                  v-model.trim="teamMemberName"
                  maxlength="50"
                />
              </div>
              <div
                class="flex relative flex-col mt-3 text-text-primary font-semibold text-sm"
              >
                <!-- <label class="">Date</label> -->

                <input
                  class="border rounded border-border-primary p-3.75 text-sm font-medium focus:outline-none input-shadow"
                  v-model="teamMemberRole"
                  placeholder="Role"
                />
              </div>
              <div
                class="flex flex-col my-3 text-text-primary font-semibold text-sm"
              >
                <div
                  class="border rounded border-border-primary flex items-center overflow-hidden w-full"
                >
                  <AttachmentIcon class="mx-5 h-6.5 fill-current" />
                  <label
                    class="p-4 w-full border-l border-border-primary text-sm font-semibold text-text-alternate-3 opacity-70"
                    >Choose image (jpg, jpeg, png) (Max. 1MB)
                    <input
                      type="file"
                      hidden
                      accept="image/png,image/jpeg,image/jpg"
                      @change="handleFileInputTeamMember"
                    />
                  </label>
                </div>
                <p class="font-medium text-text-alternate-3 text-xs">
                  Add an image of the team member
                </p>
              </div>
              <div class="overflow-x-auto py-1" v-if="teamMemberImage">
                <div class="inline-flex gap-3">
                  <div class="w-72 relative rounded-md overflow-hidden">
                    <button
                      class="text-2xl bg-bg-primary rounded-full overflow-hidden h-5 w-5 absolute z-10 right-4 top-4 cursor-pointer"
                      @click.prevent="removeTeamMemberImage()"
                    >
                      <span class="inline-block transform -translate-y-1">
                        &times;
                      </span>
                    </button>
                    <img :src="teamMemberImage" alt="image" />
                  </div>
                </div>
              </div>
              <div
                class="flex relative flex-col mt-3 text-text-primary font-semibold text-sm"
              >
                <!-- <label class="">Date</label> -->

                <textarea
                  class="border rounded border-border-primary p-3.75 text-sm font-medium focus:outline-none input-shadow"
                  v-model="teamMemberDescription"
                  rows="4"
                  placeholder="Description"
                />
              </div>
              <button
                :disabled="!isTeamMemberFilled"
                @click="handleAddTeamMember"
                type="button"
                class="bg-btn-bg-alternate-1 h-12 w-full rounded mt-3 text-sm font-bold text-text-alternate-10"
              >
                Add Member
              </button>

              <div
                class="border border-border-alternate-6 mt-6 rounded-lg p-4"
                v-for="(team, index) in teamMembers"
                :key="index"
              >
                <CloseIcon
                  class="float-right"
                  @click="removeTeamMember(index)"
                />

                <div class="flex items-center">
                  <div>
                    <img
                      :src="team.image"
                      class="object-cover w-20 h-20 rounded-full"
                      alt=""
                    />
                  </div>
                  <div class="ml-4">
                    <p class="font-semibold text-sm text-text-alternate-1">
                      {{ team.name }}
                    </p>
                    <p class="font-semibold text-text-alternate-3 text-xs mt-3">
                      {{ team.role }}
                    </p>
                  </div>
                </div>
                <p class="mt-3 text-sm font-medium text-text-alternate-1">
                  {{ team.description }}
                </p>
              </div>

              <hr class="my-6 border-border-primary border border-t-0" />

              <div
                class="rounded bg-bg-alternate-3 p-4 my-4 relative text-text-primary"
              >
                <div class="flex justify-between">
                  <h3 class="font-semibold mb-2 text-sm xs:text-base">
                    Make Private
                  </h3>
                  <BToggle v-model="isPrivate" />
                </div>
                <p class="text-2xs xs:text-xs">
                  Hide your fundraiser from the public. You can still share the
                  link with anyone to recieve donations.
                </p>
              </div>

              <!-- <div
                class="
                  rounded
                  bg-bg-alternate-3
                  p-4
                  my-4
                  relative
                  text-text-primary
                "
              >
                <div class="flex justify-between">
                  <h3 class="font-semibold mb-2 text-sm xs:text-base">
                    Allow “Make A Wish”
                  </h3>
                  <BToggle v-model="allowMakeAWish" />
                </div>
                <p class="text-2xs xs:text-xs">
                  Turn on/off the ability for users to use Make A wish feature.
                  Learn more about Make A Wish here.
                </p>
              </div> -->

              <div
                class="rounded bg-bg-alternate-3 p-4 my-4 relative text-text-primary"
              >
                <div class="flex justify-between">
                  <h3 class="font-semibold mb-2 text-sm xs:text-base">
                    Allow Promote and Earn
                  </h3>
                  <BToggle v-model="allowPromoteAndEarn" />
                </div>
                <p class="text-2xs xs:text-xs">
                  Give back to people who donate to your fundraiser as a show of
                  appreciation and encouragement.
                </p>
              </div>

              <div v-if="allowPromoteAndEarn">
                <div
                  class="flex flex-col my-3 text-text-primary font-semibold text-sm"
                >
                  <label>Commission percentage (%)</label>
                  <input
                  class="
                    border
                    rounded
                    border-border-primary
                    my-6
                    text-sm
                    font-medium slider h-1
                    focus:outline-none
                  "
                  type="range"
                  placeholder="20"
                  v-model.number="promoteAndEarnCommPercent"
                  min="20"
                  max="50"
                  pattern="\d*"
                  inputmode="decimal"
                />
                <span
                    class="
                      rounded
                      border border-border-primary
                      px-4
                      py-3.5
                      min-w-36
                      max-w-min
                      font-medium
                      mb-3
                    "
                  >
                    {{ promoteAndEarnCommPercent }}%
                  </span>
                  <p class="font-medium text-text-alternate-3 text-xs">
                    Specify your Promote and Earn Commission percentage, should
                    be between 20 and 50%
                  </p>
                </div>
              </div>
              <div
                class="rounded bg-bg-alternate-3 p-4 my-4 relative text-text-primary"
              >
                <div class="flex justify-between items-center mb-4">
                  <h3 class="font-semibold text-sm xs:text-base">
                    Monthly Donation
                  </h3>
                  <button
                    class="focus:outline-none border border-border-alternate-4 text-text-alternate-4 font-semibold rounded-full w-14 text-xs py-0.5"
                    @click.prevent="handleMonthlyButton"
                  >
                    {{ canEdit ? "Save" : "Edit" }}
                  </button>
                </div>
                <p class="text-2xs xs:text-xs mt-2 mb-8">
                  Solicit monthly donations from your donors by specifying
                  subscription amounts. E.g. $5/Month
                </p>
                <div
                  class="flex flex-col my-3 text-text-primary font-semibold text-sm"
                >
                  <label class="text-xs">Silver Donor</label>
                  <div class="flex my-2 w-full items-center">
                    <input
                      :class="[
                        'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                        { 'bg-bg-alternate-3': !canEdit },
                      ]"
                      :readonly="!canEdit"
                      type="number"
                      v-model.number="silverDonor"
                      pattern="\d*"
                      inputmode="decimal"
                      min="0"
                    />
                    <p class="ml-2 whitespace-nowrap">Per Month</p>
                  </div>
                </div>
                <div
                  class="flex flex-col my-3 text-text-primary font-semibold text-sm"
                >
                  <label class="text-xs">Gold Donor</label>
                  <div class="flex my-2 w-full items-center">
                    <input
                      :class="[
                        'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                        { 'bg-bg-alternate-3': !canEdit },
                      ]"
                      :readonly="!canEdit"
                      type="number"
                      v-model.number="goldDonor"
                      pattern="\d*"
                      inputmode="decimal"
                      min="0"
                    />
                    <p class="ml-2 whitespace-nowrap">Per Month</p>
                  </div>
                </div>
                <div
                  class="flex flex-col my-3 text-text-primary font-semibold text-sm"
                >
                  <label class="text-xs">Diamond Donor</label>
                  <div class="flex my-2 w-full items-center">
                    <input
                      :class="[
                        'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                        { 'bg-bg-alternate-3': !canEdit },
                      ]"
                      :readonly="!canEdit"
                      type="number"
                      v-model.number="diamondDonor"
                      pattern="\d*"
                      inputmode="decimal"
                      min="0"
                    />
                    <p class="ml-2 whitespace-nowrap">Per Month</p>
                  </div>
                </div>
              </div>

              <!-- <div
              class="
                rounded
                bg-bg-alternate-3
                p-4
                my-4
                relative
                text-text-primary
              "
            >
              <div class="flex justify-between">
                <h3 class="font-semibold mb-2 text-sm xs:text-base">
                  Reward blessors
                </h3>
                <BToggle v-model="isRewarding" />
              </div>
              <p class="text-2xs xs:text-xs">
                Give back to people who donate to your fundraiser as a show of
                appreciation and encouragement.
              </p>
            </div>

            <div v-if="isRewarding">
              <div
                class="
                  flex flex-col
                  my-3
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <label>Blessing Amount</label>
                <input
                  class="
                    border
                    rounded
                    border-border-primary
                    my-2
                    p-4
                    text-sm
                    font-medium
                    focus:outline-none
                  "
                  type="number"
                  placeholder="0.0"
                  v-model.number="amount"
                  pattern="\d*"
                  inputmode="decimal"
                  min="0"
                />
                <p class="font-medium text-text-alternate-3 text-xs">
                  Enter a maximum amount for this reward
                </p>
              </div>
              <div
                class="
                  flex flex-col
                  mt-6
                  mb-3
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <label>Reward</label>
                <textarea
                  class="
                    border
                    rounded
                    border-border-primary
                    my-2
                    p-4
                    text-sm
                    font-medium
                    focus:outline-none
                  "
                  v-model="reward"
                />
              </div>
              <button
                class="
                  bg-btn-bg-alternate-1
                  text-btn-text-alternate-1
                  w-full
                  py-4
                  rounded
                  text-center text-sm
                  xs:text-base
                  font-bold
                "
                @click.prevent="handleReward"
                :disabled="!amount || !reward"
              >
                Add Reward
              </button>

              <hr class="text-text-alternate-8 my-6" />

              <div v-if="rewards.length">
                <div
                  class="
                    rounded
                    border border-border-alternate-1
                    p-2
                    xs:py-4
                    my-2
                    relative
                    text-text-primary
                  "
                  v-for="(rewardValue, index) in rewards"
                  :key="`${index}-${rewardValue.amount}`"
                >
                  <div class="flex justify-between items-center mb-2 xs:pr-2">
                    <span
                      class="font-semibold text-xs xs:text-sm pl-2"
                      style="color: #577387"
                    >
                      ₦{{ rewardValue.amount.toLocaleString() }} or more
                    </span>
                    <button
                      class="text-3xl overflow-hidden h-4"
                      @click.prevent="removeReward(index)"
                    >
                      <span class="inline-block transform -translate-y-3.5">
                        &times;
                      </span>
                    </button>
                  </div>
                  <p
                    class="
                      text-2xs
                      xs:text-xs
                      text-text-primary
                      font-medium
                      px-2
                    "
                  >
                    {{ rewardValue.reward }}
                  </p>
                </div>

                <hr class="text-text-alternate-8 my-5" />
              </div>
            </div> -->

              <div class="flex">
                <button
                  @click="handlePrev"
                  type="button"
                  class="text-text-primary text-left font-bold text-sm w-2/3"
                >
                  Previous
                </button>
                <button
                  class="bg-btn-bg-primary text-btn-text-primary rounded w-full py-4 mt-2 font-bold text-sm xs:text-base"
                  type="submit"
                  :disabled="!isFilled"
                >
                  Create Fundraiser
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref, computed, watch } from "vue";
import BToggle from "@/components/ui/BToggle";
import ProjectImageCrop from "../ProjectImageCrop";
import { getProjectData } from "@/utils";
import { useApi } from "@/cmp-functions/useApi";
import { postFundraiser, postAnImage, searchUsers } from "@/services/api";
import { useStore } from "vuex";
import BSpinner from "@/components/ui/BSpinner";
import randomColor from "randomcolor";
import imageCompression from "browser-image-compression";
import infoData from "@/data/info.js";
import BToolTipBtn from "@/components/ui/BToolTipBtn";
import { useToast } from "vue-toastification";

const data = {
  impactStoryTitle: "",
  impactStoryDescription: "",
  impactStoryImage: null,
  impactStories: [],
  teamMemberName: "",
  teamMemberRole: "",
  teamMemberImage: null,
  teamMemberDescription: "",
  teamMembers: [],
  allowMakeAWish: true,
  isPrivate: false,
  isOrganiserBeneficiary: true,
  allowPromoteAndEarn: true,
  promoteAndEarnCommPercent: 30,
};
const notRequired = {
  facebook: "",
  instagram: "",
  twitter: "",
  youtube: "",
  tiktok: "",
  youTubeLink: "",
  beneficiary: { fullname: "", link: "" },
};
const oneOffData = { members: [] };
const recurringData = {
  monthlyDonations: [
    {
      donationtype: "SILVER",
      amount: 5,
    },
    {
      donationtype: "GOLD",
      amount: 10,
    },
    {
      donationtype: "DIAMOND",
      amount: 50,
    },
  ],
};

export default {
  name: "ProjectDetailsThree",

  emits: ["next", "prev"],

  props: ["project"],

  components: {
    ProjectImageCrop,
    BToggle,
    BSpinner,
    BToolTipBtn,
  },

  setup(props, { emit }) {
    const cropImage = ref(false);
    const cropImageTeamMember = ref(false);
    const store = useStore();
    const authUser = store.state.auth.authData;
    const isCompressing = ref(false);
    const imageErrorMsg = ref("");
    const toast = useToast();

    const toggleCropImage = () => {
      cropImage.value = !cropImage.value;
    };

    const toggleCropImageTeamMember = () => {
      cropImageTeamMember.value = !cropImageTeamMember.value;
    };

    const isOneTime = props.project.fundraiserType !== "RECURRING";

    const initFormInputs = {
      ...data,
      ...(isOneTime ? oneOffData : recurringData),
    };

    const formInputs = reactive({
      ...Object.keys(initFormInputs).reduce(
        (acc, next) => ({
          ...acc,
          [next]:
            props.project[next] !== undefined
              ? props.project[next]
              : initFormInputs[next],
        }),
        {}
      ),
      ...Object.keys(notRequired).reduce(
        (acc, next) => ({
          ...acc,
          [next]:
            props.project[next] !== undefined
              ? props.project[next]
              : notRequired[next],
        }),
        {}
      ),
    });
    const blessingForm = reactive({
      amount: "",
      reward: "",
      file: "",

      memberUsername: "",
      canEdit: false,
      isRewarding: props.project.rewards?.length > 0,
      silverDonor: 5,
      goldDonor: 10,
      diamondDonor: 50,
    });

    watch(blessingForm, ({ isRewarding }) => {
      if (!isRewarding) {
        formInputs.rewards = [];
      }
    });

    const [fundraiserResponse, createFundraiser] = useApi(postFundraiser);
    const [impactStoryImageResponse, postImpactStoryImage] =
      useApi(postAnImage);
    const [teamMemberImageResponse, postTeamMemberImage] = useApi(postAnImage);

    watch(fundraiserResponse, ({ isSuccess, data }) => {
      if (isSuccess) {
        emit("next", { ...formInputs, id: data.id });
      }
    });

    watch(impactStoryImageResponse, ({ isSuccess, isFailure, data, error }) => {
      if (isSuccess) {
        formInputs.impactStoryImage = data.imageurl;
        blessingForm.file = null;
        toggleCropImage();
      } else if (isFailure) {
        toast.error(error);
      }
    });

    watch(teamMemberImageResponse, ({ isSuccess, isFailure, data, error }) => {
      if (isSuccess) {
        formInputs.teamMemberImage = data.imageurl;
        blessingForm.file = null;
        toggleCropImageTeamMember();
      } else if (isFailure) {
        toast.error(error);
      }
    });
    const removeImpactStoryImage = () => {
      formInputs.impactStoryImage = null;
    };

    const removeTeamMemberImage = () => {
      formInputs.impactStoryImage = null;
    };

    const handleSaveImpactStory = () => {
      const value = {
        title: formInputs.impactStoryTitle,
        image: formInputs.impactStoryImage,
        description: formInputs.impactStoryDescription,
      };

      formInputs.impactStories.push(value);

      formInputs.impactStoryTitle = "";
      formInputs.impactStoryDescription = "";
      formInputs.impactStoryImage = null;
    };

    const handleAddTeamMember = () => {
      const value = {
        name: formInputs.teamMemberName,
        role: formInputs.teamMemberRole,
        description: formInputs.teamMemberDescription,
        image: formInputs.teamMemberImage,
      };

      formInputs.teamMembers.push(value);

      formInputs.teamMemberName = "";
      formInputs.teamMemberRole = "";
      formInputs.teamMemberDescription = "";
      formInputs.teamMemberImage = null;
    };

    const isImpactStoryFilled = computed(() => {
      if (
        formInputs.impactStoryTitle &&
        formInputs.impactStoryDescription &&
        formInputs.impactStoryImage
      ) {
        return true;
      } else {
        return false;
      }
    });

    const isTeamMemberFilled = computed(() => {
      if (
        formInputs.teamMemberName &&
        formInputs.teamMemberRole &&
        formInputs.teamMemberImage &&
        formInputs.teamMemberDescription
      ) {
        return true;
      } else {
        return false;
      }
    });

    const handleSave = async (image) => {
      const imageSize = Math.round(
        Buffer.from(image.substring(image.indexOf(",") + 1)).length / 1e3
      );
      if (imageSize > 1000) {
        toast.error(
          "Image size is too large, Maximum image size should be 1MB."
        );
      } else {
        const { name } = blessingForm.file;
        postImpactStoryImage({
          file: { name, mimetype: "image/jpeg", base64: image },
        });
      }
    };

    const handleSaveTeamMember = async (image) => {
      const imageSize = Math.round(
        Buffer.from(image.substring(image.indexOf(",") + 1)).length / 1e3
      );
      if (imageSize > 1000) {
        toast.error(
          "Image size is too large, Maximum image size should be 1MB."
        );
      } else {
        const { name } = blessingForm.file;
        postTeamMemberImage({
          file: { name, mimetype: "image/jpeg", base64: image },
        });
      }
    };

    const handleSubmit = () => {
      const payload = getProjectData(
        { ...props.project, ...formInputs },
        authUser.id
      );
      createFundraiser(payload);
    };

    const handlePrev = () => {
      emit("prev", formInputs);
    };

    const handleMonthlyButton = () => {
      const { silverDonor, goldDonor, diamondDonor, canEdit } = blessingForm;
      if (canEdit) {
        formInputs.monthlyDonations = [
          { donationtype: "SILVER", amount: silverDonor },
          { donationtype: "GOLD", amount: goldDonor },
          { donationtype: "DIAMOND", amount: diamondDonor },
        ];
      }

      blessingForm.canEdit = !blessingForm.canEdit;
    };

    const validateFileInput = async (target) => {
      let file = target.files[0];
      let image = new Image();
      let reader = new FileReader();
      let width, height;
      reader.onload = async (e) => {
        image.src = e.target.result;

        image.onload = () => {
          width = image.width;
          height = image.height;

          if (image.width < 630 || image.height < 1200) {
            toast.error(
              "Image resolution is too low, image size should be atleast 630 x 1200px"
            );
            imageErrorMsg.value =
              "Image resolution is too low, image size should be atleast 630 x 1200px";
          } else {
            imageErrorMsg.value = "";
          }
        };
      };

      reader.readAsDataURL(file);

      console.log("Image Width: ", width);
      console.log("Image Height: ", height);
      console.log("Image error: ", imageErrorMsg.value);

      if (imageErrorMsg.value == "") {
        return true;
      } else {
        return false;
      }
    };

    const handleFileInput = async ({ target }) => {
      try {
        isCompressing.value = true;
        let file = target.files[0];
        blessingForm.file = await imageCompression(file, { maxSizeMB: 0.05 });
        toggleCropImage();
      } catch (error) {
        console.log(error);
      } finally {
        isCompressing.value = false;
      }
    };

    const handleFileInputTeamMember = async ({ target }) => {
      try {
        isCompressing.value = true;
        let file = target.files[0];
        blessingForm.file = await imageCompression(file, { maxSizeMB: 0.05 });
        toggleCropImageTeamMember();
      } catch (error) {
        console.log(error);
      } finally {
        isCompressing.value = false;
      }
    };

    const removeImpactStory = (index) => {
      if (index > -1) {
        // only splice array when item is found
        formInputs.impactStories.splice(index, 1); // 2nd parameter means remove one item only
      }
    };

    const removeTeamMember = (index) => {
      if (index > -1) {
        // only splice array when item is found
        formInputs.teamMembers.splice(index, 1); // 2nd parameter means remove one item only
      }
    };

    const removeMember = (index) => {
      formInputs.members = formInputs.members.filter((_, ind) => index !== ind);
    };

    const handleCancel = () => {
      blessingForm.file = null;
      toggleCropImage();
    };
    const handleCancelTeamMember = () => {
      blessingForm.file = null;
      toggleCropImageTeamMember();
    };
    // Object.keys(initFormInputs).every(
    //   (key) => formInputs[key] !== null && formInputs[key] !== ''
    // )

    const isFilled = computed(() => {
      return true;
    });

    const alreadySelected = (id) => {
      return formInputs.members.some(
        ({ userdetail }) => userdetail.userid == id
      );
    };

    return {
      ...toRefs(formInputs),
      ...toRefs(blessingForm),
      isCompressing,
      alreadySelected,
      cropImage,
      toggleCropImage,
      toggleCropImageTeamMember,
      cropImageTeamMember,
      isOneTime,
      searchUsers,
      randomColor,
      isFilled,
      isImpactStoryFilled,
      handleSubmit,
      handleSaveImpactStory,
      removeImpactStoryImage,
      handleSaveTeamMember,
      handleAddTeamMember,
      isTeamMemberFilled,
      postTeamMemberImage,
      fundraiserResponse,
      impactStoryImageResponse,
      handleSave,
      handleCancel,
      removeImpactStory,
      removeTeamMemberImage,
      removeTeamMember,
      removeMember,
      handleFileInput,
      handleFileInputTeamMember,
      handlePrev,
      imageErrorMsg,
      infoData,
      validateFileInput,
      handleMonthlyButton,
      handleCancelTeamMember,
    };
  },
};
</script>
