<template>
  <div>
    <div class="lg:hidden">
      <div class="px-6 py-4 lg:mt-32">
        <header class="font-semibold text-lg flex text-text-alternate-1 pr-6">
          <button @click="$emit('prev')">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <h2 class="mx-auto">Fundraiser Type</h2>
        </header>
        <div>
          <p
            class="
              my-6
              xs:my-8
              font-medium
              text-text-alternate-7 text-sm
              xs:text-base
            "
          >
            Choose a type of project you want to raise money for
          </p>
          <form @submit.prevent="handleContinue">
            <label
              :class="[
                'block border rounded bg-bg-alternate-3 p-4 xs:p-5 my-4 xs:my-5 relative border-box cursor-pointer',
                type === 'ONEOFF' ? 'border-first' : 'border-default',
              ]"
            >
              <TickIcon
                v-if="type === 'ONEOFF'"
                class="h-5 xs:h-6 absolute right-4 top-4 z-10 fill-current"
                style="fill: #017622"
              />
              <h3
                class="
                  font-semibold
                  mb-2
                  text-sm
                  xs:text-base
                  text-text-alternate-1
                "
              >
                One-Time
              </h3>
              <p class="text-text-alternate-3 font-medium text-xs xs:text-sm">
                I’d like to create a fundraiser for a specific event/situation.
                E.g. Wedding, Emergency, etc
              </p>
              <input
                type="radio"
                name="One Time"
                value="ONEOFF"
                hidden
                v-model="type"
              />
            </label>
            <label
              :class="[
                'block border rounded bg-bg-alternate-6 p-4 xs:p-5 my-4 xs:my-5 relative cursor-pointer',
                type === 'PERMANENT' ? 'border-second' : 'border-default',
              ]"
            >
              <TickIcon
                v-if="type === 'PERMANENT'"
                class="h-5 xs:h-6 absolute right-4 top-4 z-10 fill-current"
                style="fill: #017622"
              />
              <h3
                class="
                  font-semibold
                  mb-2
                  text-sm
                  xs:text-base
                  text-text-alternate-1
                "
              >
                Permanent
              </h3>
              <p class="text-text-alternate-3 font-medium text-xs xs:text-sm">
                I'd like to create a permanent fundraiser page for my
                organisation or charity.
              </p>
              <input
                type="radio"
                name="Permanent"
                value="PERMANENT"
                :disabled="authUser.accountType == 'INDIVIDUAL'"
                hidden
                v-model="type"
              />
            </label>
            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                rounded
                w-full
                py-4
                my-4
                font-bold
                text-sm
                xs:text-base
              "
              type="submit"
              :disabled="!type"
            >
              Continue
            </button>
          </form>
        </div>
      </div>
    </div>

    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24">
        <div class="px-26 py-4 pt-20">
          <header class="font-semibold text-lg flex text-text-alternate-1 pr-6">
            <button @click="$emit('prev')">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto">Fundraiser Type</h2>
            <!-- {{ authUser.accountType }} -->
          </header>
          <div>
            <p
              class="
                my-6
                xs:my-8
                font-medium
                text-text-alternate-7 text-sm
                xs:text-base
              "
            >
              Choose a type of project you want to raise money for
            </p>
            <form @submit.prevent="handleContinue">
              <label
                :class="[
                  'block border rounded bg-bg-alternate-3 p-4 xs:p-5 my-4 xs:my-5 relative border-box cursor-pointer',
                  type === 'ONEOFF' ? 'border-first' : 'border-default',
                ]"
              >
                <TickIcon
                  v-if="type === 'ONEOFF'"
                  class="h-5 xs:h-6 absolute right-4 top-4 z-10 fill-current"
                  style="fill: #017622"
                />
                <h3
                  class="
                    font-semibold
                    mb-2
                    text-sm
                    xs:text-base
                    text-text-alternate-1
                  "
                >
                  One-Time
                </h3>
                <p class="text-text-alternate-3 font-medium text-xs xs:text-sm">
                  I’d like to create a fundraiser for a specific
                  event/situation. E.g. Wedding, Emergency, etc
                </p>
                <input
                  type="radio"
                  name="One Time"
                  value="ONEOFF"
                  hidden
                  v-model="type"
                />
              </label>
              <label
                :class="[
                  'block border rounded bg-bg-alternate-6 p-4 xs:p-5 my-4 xs:my-5 relative cursor-pointer',
                  type === 'PERMANENT' ? 'border-second' : 'border-default',
                ]"
              >
                <TickIcon
                  v-if="type === 'PERMANENT'"
                  class="h-5 xs:h-6 absolute right-4 top-4 z-10 fill-current"
                  style="fill: #017622"
                />
                <h3
                  class="
                    font-semibold
                    mb-2
                    text-sm
                    xs:text-base
                    text-text-alternate-1
                  "
                >
                  Permanent
                </h3>
                <p class="text-text-alternate-3 font-medium text-xs xs:text-sm">
                  I'd like to create a permanent fundraiser page for my
                  organisation or charity.
                </p>
                <input
                  type="radio"
                  name="Permanent"
                  value="PERMANENT"
                  hidden
                  :disabled="authUser.accountType == 'INDIVIDUAL'"
                  v-model="type"
                />
              </label>
              <button
                class="
                  bg-btn-bg-primary
                  text-btn-text-primary
                  rounded
                  w-full
                  py-4
                  my-4
                  font-bold
                  text-sm
                  xs:text-base
                "
                type="submit"
                :disabled="!type"
              >
                Continue
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'ProjectType',

  emits: ['next', 'prev'],

  props: ['project'],

  setup(props, { emit }) {
    const store = useStore();
    const authUser = computed(() => store.state.auth.authData);
    const type = ref(props.project.fundraiserType);

    const handleContinue = () => {
      emit('next', { fundraiserType: type.value });
    };

    onMounted(() => {
      
      if (authUser.value.accountType == 'INDIVIDUAL') {
        type.value = 'ONEOFF';
        handleContinue();
      }
    });

    return { type, handleContinue, authUser };
  },
};
</script>

<style lang="scss" scoped>
.border-first {
  border-color: #567286;
}

.border-default {
  border-color: transparent;
}

.border-second {
  border-color: #9f771f;
}
</style>
