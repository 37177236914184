<template>
  <div>
    <component
      v-if="isVerified"
      :is="
        project.fundraiserType == 'ONEOFF'
          ? componentName
          : componentNamePermanent
      "
      @next="handleNext"
      @prev="handlePrev"
      :project="project"
    />

    <div class="px-6" v-else>
      <div class="lg:hidden">
        <header class="font-semibold text-lg flex text-text-alternate-1 pr-6">
          <button @click="$router.go(-1)">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
        </header>
        <div class="py-6 px-2 xs:px-4">
          <FailureIcon class="mb-8 mx-auto" />
          <div
            class="text-xs text-text-alternate-3 text-center font-medium"
            v-if="!authUser.isEmailVerified"
          >
            <h4 class="font-semibold text-text-primary text-lg my-2">
              Email not Verifed
            </h4>
            <p>
              You're almost there. We sent a verification message to your
              registered email address:
            </p>
            <p class="font-semibold my-3">{{ authUser.email }}</p>
            <p>
              Kindly click on the verification link in that email to verify your
              email address so you can create fundraisers
            </p>
          </div>
          <div
            class="
              text-xs text-text-alternate-3 text-center
              font-medium
              xs:px-2
            "
            v-else-if="authUser.verificationStatus === 'UNVERIFIED'"
          >
            <h4 class="font-semibold text-text-primary text-lg my-2">
              Profile pending verification
            </h4>
            <p class="mb-4">
              We are working to verify your profile as quickly as possible.
              Profile verification can take as long as 48 hours.
            </p>
            <p>
              Unfortunately, you cannot create fundraisers while your profile is
              pending verification. Once your profile has been verified, you
              will be able to create fundraisers.
            </p>
          </div>
        </div>
      </div>
      <div class="hidden lg:flex justify-center pt-4">
        <div class="b-card px-26 mt-24 pt-8">
          <header class="font-semibold text-lg flex text-text-alternate-1 pr-6">
            <button @click="$router.go(-1)">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
          </header>
          <div class="py-6 px-2 xs:px-4">
            <FailureIcon class="mb-8 mx-auto" />
            <div
              class="text-xs text-text-alternate-3 text-center font-medium"
              v-if="!authUser.isEmailVerified"
            >
              <h4 class="font-semibold text-text-primary text-lg my-2">
                Email not Verifed
              </h4>
              <p>
                You're almost there. We sent a verification message to your
                registered email address:
              </p>
              <p class="font-semibold my-3">{{ authUser.email }}</p>
              <p>
                Kindly click on the verification link in that email to verify
                your email address so you can create fundraisers
              </p>
            </div>
            <div
              class="
                text-xs text-text-alternate-3 text-center
                font-medium
                xs:px-2
              "
              v-else-if="authUser.verificationStatus === 'UNVERIFIED'"
            >
              <h4 class="font-semibold text-text-primary text-lg my-2">
                Profile pending verification
              </h4>
              <p class="mb-4">
                We are working to verify your profile as quickly as possible.
                Profile verification can take as long as 48 hours.
              </p>
              <p>
                Unfortunately, you cannot create fundraisers while your profile
                is pending verification. Once your profile has been verified,
                you will be able to create fundraisers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import ProjectType from './ProjectType';
import ProjectDetailsOne from './OneOff/ProjectDetailsOne';
import ProjectDetailsTwo from './OneOff/ProjectDetailsTwo';
import ProjectDetailsPermanentOne from './Permanent/ProjectDetailsOne.vue';
import ProjectDetailsPermnanentTwo from './Permanent/ProjectDetailsTwo.vue';
import ProjectDetailsPermanentThree from './Permanent/ProjectDetailsThree.vue';
import ProjectSubmitted from './OneOff/ProjectSubmitted';
import ProjectPromotion from './ProjectPromotion';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'CreateProject',

  components: {
    ProjectType,
    ProjectDetailsOne,
    ProjectDetailsTwo,
    ProjectSubmitted,
    ProjectPromotion,
    ProjectDetailsPermanentOne,
    ProjectDetailsPermnanentTwo,
    ProjectDetailsPermanentThree,
  },

  setup() {
    let active = ref(1); // change to 1 to enable projectType
    const store = useStore();
    const authUser = computed(() => store.state.auth.userDetails);
    const isVerified = computed(
      () =>
        authUser.value.isEmailVerified &&
        authUser.value.verificationStatus !== 'UNVERIFIED'
    );

    const componentName = computed(() => {
      switch (active.value) {
        case 1:
          return ProjectType;
        case 2:
          return ProjectDetailsOne;
        case 3:
          return ProjectDetailsTwo;
        case 4:
          return ProjectSubmitted;
        case 5:
          return ProjectPromotion;
        default:
          throw Error('Invalid active component');
      }
    });

    const componentNamePermanent = computed(() => {
      switch (active.value) {
        case 1:
          return ProjectType;
        case 2:
          return ProjectDetailsPermanentOne;
        case 3:
          return ProjectDetailsPermnanentTwo;
        case 4:
          return ProjectDetailsPermanentThree;
        case 5:
          return ProjectSubmitted;
        case 6:
          return ProjectPromotion;
        default:
          throw Error('Invalid active component');
      }
    });

    const project = ref({});
    const router = useRouter();
    const handleNext = (data) => {
      if (data) {
        project.value = { ...project.value, ...data };
        // project.value.fundraiserType = 'ONEOFF'; // Force all fundraisers to be One off: Kindly remove if you want to reenable recurring fundraiser
      }
      active.value += 1;
    };

    const handlePrev = (data) => {
      if (active.value === 2) return router.go(-1);
      if (data) {
        project.value = { ...project.value, ...data };
      }
      active.value -= 1;
    };

    

    return {
      componentName,
      componentNamePermanent,
      handleNext,
      project,
      handlePrev,
      authUser,
      isVerified,
    };
  },
};
</script>
