<template>
  <div>
    <div class="lg:hidden">
      <BPageLoader v-if="isLoading" />
      <div
        class="
          p-6
          lg:pt-24
          flex flex-grow flex-col
          text-text-primary
          justify-center
          pt-14
        "
        v-else-if="promotionResponse.isSuccess"
      >
        <div class="text-center pb-6 flex flex-col items-center justify-center">
          <SuccessIcon class="mb-12 mt-4 mx-auto" />
          <h4 class="font-semibold text-lg">Congratulations</h4>
          <p class="text-sm max-w-xs text-text-primary mt-4 px-7">
            Your promotion payment was successful. Your fundraiser project will
            be reviewed by the admin in less than 48hrs.
          </p>
          <div class="flex flex-col w-full max-w-sm mt-6">
            <router-link
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                my-2
                xs:my-3
                py-4
                rounded
                text-center text-sm
                xs:text-base
                font-bold
              "
              :to="{ name: 'Overview', params: { slug } }"
            >
              View Fundraiser
            </router-link>
          </div>
        </div>
      </div>
      <div
        class="
          p-6
          flex flex-grow flex-col
          text-text-primary
          justify-center
          pt-14
        "
        v-else-if="promotionResponse.isFailure"
      >
        <div class="text-center flex flex-col items-center">
          <FailureIcon class="my-4 mx-auto" />
          <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
          <p class="text-xs xs:text-sm text-alternate-3">
            {{ promotionResponse.error || 'failed' }}
          </p>
          <button
            class="
              bg-btn-bg-primary
              max-w-sm
              text-btn-text-primary
              rounded
              w-full
              mt-10
              py-4
              font-bold
              text-sm
              xs:text-base
              focus:outline-none
            "
            @click="promotionResponse.isFailure = false"
          >
            Ok
          </button>
        </div>
      </div>
      <div class="flex flex-col items-center p-6 min-h-screen" v-else>
        <header class="font-semibold text-lg flex text-text-alternate-1">
          <h2 class="mx-auto">Promote Fundraiser</h2>
        </header>
        <p class="text-sm font-medium text-text-primary my-4">
          Promoting your fundraiser places it at the top of every page,
          increasing its visibility and chances of receiving donations
        </p>
        <div class="w-full">
          <label
            v-for="promo in data"
            :key="promo.id"
            :class="[
              'block rounded p-4 xs:p-5 my-4 xs:my-5 relative border-box cursor-pointer',
              `promo-${promo.id}`,
              { active: type === promo.id },
            ]"
          >
            <TickIcon
              v-if="type === promo.id"
              class="h-4 xs:h-5 absolute right-4 top-4 z-10 fill-current"
              style="fill: #017622"
            />
            <h3
              class="
                font-semibold
                mb-2
                text-sm
                xs:text-base
                text-text-alternate-1
              "
            >
              <span :class="['text-text-alternate-1 font-bold amount']">
                ₦{{ promo.amount.toLocaleString() }}
              </span>
              <span class="text-text-alternate-1 font-semibold text-sm mt-1">
                /{{ promo.plan }}
              </span>
            </h3>
            <p class="text-text-alternate-3 font-medium text-xs xs:text-sm">
              {{ promo.plandesc }}
            </p>
            <input
              type="radio"
              :name="promo.plan"
              :value="promo.id"
              hidden
              v-model="type"
            />
          </label>
        </div>
        <div class="flex flex-col w-full mt-6">
          <button
            class="
              bg-btn-bg-primary
              text-btn-text-primary
              my-2
              xs:my-3
              py-4
              rounded
              text-center text-sm
              xs:text-base
              font-bold
            "
            :disabled="!type"
            @click="isModal = true"
          >
            Pay
          </button>
          <router-link
            class="
              bg-btn-bg-alternate-1
              text-btn-text-alternate-1
              my-2
              xs:my-3
              py-4
              rounded
              text-center text-sm
              xs:text-base
              font-bold
            "
            :to="{ name: 'Overview', params: { slug } }"
            >View Fundraiser</router-link
          >
        </div>
      </div>
    </div>
    <div class="hidden lg:flex justify-center">
      <div class="b-card px-26 pt-8 mt-24">
        <BPageLoader v-if="isLoading" />
        <div
          class="
            p-6
            lg:pt-24
            flex flex-grow flex-col
            text-text-primary
            justify-center
            pt-14
          "
          v-else-if="promotionResponse.isSuccess"
        >
          <div
            class="text-center pb-6 flex flex-col items-center justify-center"
          >
            <SuccessIcon class="mb-12 mt-4 mx-auto" />
            <h4 class="font-semibold text-lg">Congratulations</h4>
            <p class="text-sm max-w-xs text-text-primary mt-4 px-7">
              Your promotion payment was successful. Your fundraiser project
              will be reviewed by the admin in less than 48hrs.
            </p>
            <div class="flex flex-col w-full max-w-sm mt-6">
              <router-link
                class="
                  bg-btn-bg-primary
                  text-btn-text-primary
                  my-2
                  xs:my-3
                  py-4
                  rounded
                  text-center text-sm
                  xs:text-base
                  font-bold
                "
                :to="{ name: 'Overview', params: { slug } }"
              >
                View Fundraiser
              </router-link>
            </div>
          </div>
        </div>
        <div
          class="
            p-6
            flex flex-grow flex-col
            text-text-primary
            justify-center
            pt-14
          "
          v-else-if="promotionResponse.isFailure"
        >
          <div class="text-center flex flex-col items-center">
            <FailureIcon class="my-4 mx-auto" />
            <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
            <p class="text-xs xs:text-sm text-alternate-3">
              {{ promotionResponse.error || 'failed' }}
            </p>
            <button
              class="
                bg-btn-bg-primary
                max-w-sm
                text-btn-text-primary
                rounded
                w-full
                mt-10
                py-4
                font-bold
                text-sm
                xs:text-base
                focus:outline-none
              "
              @click="promotionResponse.isFailure = false"
            >
              Ok
            </button>
          </div>
        </div>
        <div class="flex flex-col items-center p-6 min-h-screen" v-else>
          <header class="font-semibold text-lg flex text-text-alternate-1">
            <h2 class="mx-auto">Promote Fundraiser</h2>
          </header>
          <p class="text-sm font-medium text-text-primary my-4">
            Promoting your fundraiser places it at the top of every page,
            increasing its visibility and chances of receiving donations
          </p>
          <div class="w-full">
            <label
              v-for="promo in data"
              :key="promo.id"
              :class="[
                'block rounded p-4 xs:p-5 my-4 xs:my-5 relative border-box cursor-pointer',
                `promo-${promo.id}`,
                { active: type === promo.id },
              ]"
            >
              <TickIcon
                v-if="type === promo.id"
                class="h-4 xs:h-5 absolute right-4 top-4 z-10 fill-current"
                style="fill: #017622"
              />
              <h3
                class="
                  font-semibold
                  mb-2
                  text-sm
                  xs:text-base
                  text-text-alternate-1
                "
              >
                <span :class="['text-text-alternate-1 font-bold amount']">
                  ₦{{ promo.amount.toLocaleString() }}
                </span>
                <span class="text-text-alternate-1 font-semibold text-sm mt-1">
                  /{{ promo.plan }}
                </span>
              </h3>
              <p class="text-text-alternate-3 font-medium text-xs xs:text-sm">
                {{ promo.plandesc }}
              </p>
              <input
                type="radio"
                :name="promo.plan"
                :value="promo.id"
                hidden
                v-model="type"
              />
            </label>
          </div>
          <div class="flex flex-col w-full mt-6">
            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                my-2
                xs:my-3
                py-4
                rounded
                text-center text-sm
                xs:text-base
                font-bold
              "
              :disabled="!type"
              @click="isModal = true"
            >
              Pay
            </button>
            <router-link
              class="
                bg-btn-bg-alternate-1
                text-btn-text-alternate-1
                my-2
                xs:my-3
                py-4
                rounded
                text-center text-sm
                xs:text-base
                font-bold
              "
              :to="{ name: 'Overview', params: { slug } }"
              >View Fundraiser</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <BModal :isOpen="isModal" @close="isModal = false">
    <BPageLoader v-if="promotionResponse.isLoading" />
    <ul class="lg:px-26" v-else>
      <li class="my-4">
        <button
          class="
            border border-btn-border-alternate-1
            btn-shadow
            w-full
            rounded
            pt-4
            pb-5
            flex
            justify-center
            gap-5
            items-center
          "
          @click="payWithWallet"
        >
          <WalletIcon class="h-8" />
          <div class="text-left flex flex-col gap-2">
            <p class="text-xs font-semibold text-text-alternate-1">
              Pay with Wallet
            </p>
            <p class="text-xs font-medium text-text-alternate-3">
              Balance:
              <span class="font-semibold"
                >₦{{ walletBalance.toLocaleString() }}</span
              >
            </p>
          </div>
        </button>
      </li>
      <li class="my-4">
        <button
          class="
            border border-btn-border-alternate-1
            btn-shadow
            w-full
            rounded
            py-3.5
            sm:py-4
          "
          @click="processToPayment(2)"
        >
          <div class="flex justify-center gap-3 py-px">
            <MasterCardIcon class="h-5" />
            <VisaIcon class="h-5" />
            <VerveIcon class="h-5" />
          </div>
          <p class="text-xs font-semibold mt-2 text-text-alternate-1">
            Pay with Paystack
          </p>
        </button>
      </li>
      <li class="my-4">
        <button
          class="
            border border-btn-border-alternate-1
            btn-shadow
            w-full
            rounded
            py-3.5
            sm:py-4
          "
          @click="processToPayment(3)"
        >
          <div class="flex justify-center gap-3 py-px">
            <MasterCardIcon class="h-5" />
            <VisaIcon class="h-5" />
            <VerveIcon class="h-5" />
          </div>
          <p class="text-xs font-semibold mt-2 text-text-alternate-1">
            Pay with Monnify
          </p>
        </button>
      </li>
    </ul>
  </BModal>
</template>

<script>
import { ref, toRefs, computed, watch } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import { getPromoPlans, makePayment } from '@/services/api';
import BModal from '@/components/ui/BModal';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

export default {
  name: 'ProjectPromotion',

  emits: ['next', 'prev'],

  props: ['project'],

  components: { BModal },

  setup(props) {
    const type = ref('');
    const isModal = ref(false);
    const [response, fetchPromoPlans] = useApi(getPromoPlans);
    fetchPromoPlans();

    const store = useStore();
    const walletBalance = computed(() => store.state.auth.userWallet.balance);
    const userDetails = computed(() => store.state.auth.userDetails);

    const [promotionResponse, makePromotionPayment] = useApi(makePayment);

    const toast = useToast();

    const slug = computed(() => {
      const { id, title } = props.project;
      return `${id}-${title
        .trim()
        .toLowerCase()
        .replace(/[^a-z\d\s:]/g, '')
        .replace(/\s+/g, '-')}`;
    });

    const selectedPromo = computed(() =>
      response.data.find(({ id }) => id === type.value)
    );

    const processToPayment = (payType) => {
      const { userid, firstname, lastname, email } = userDetails.value;
      const payload = {
        userId: userid,
        firstname,
        lastname,
        email,
        type: payType,
        fundraiserId: props.project.id,
        amount: selectedPromo.value.amount,
      };

      makePromotionPayment(payload, { promotionId: type.value });
    };

    watch(promotionResponse, ({ data, isSuccess, isFailure }) => {
      if (isSuccess && data?.link) {
        window.location.href = data.link;
      }
      if (isSuccess || isFailure) {
        isModal.value = false;
      }
    });

    const payWithWallet = () => {
      if (selectedPromo.value.amount > walletBalance.value) {
        toast.error('Insufficient Balance');
      } else {
        processToPayment(4);
      }
    };

    return {
      type,
      ...toRefs(response),
      promotionResponse,
      slug,
      processToPayment,
      walletBalance,
      payWithWallet,
      isModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.promo-1 {
  background: #e0f2ff;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;

  &.active {
    border-color: #567286;
  }
  .amount {
    color: #567286;
  }
}

.promo-2 {
  background: #feedc8;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;

  &.active {
    border-color: #9f771f;
  }
  .amount {
    color: #9f771f;
  }
}

.promo-3 {
  background: #fde0d4;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;

  &.active {
    border-color: #d15520;
  }
  .amount {
    color: #d15520;
  }
}
</style>
