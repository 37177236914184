<template>
  <div id="form">
    <div class="lg:hidden">
      <div>
        <BSpinner
          v-if="
            isCompressing ||
            fundraiserResponse.isLoading ||
            galleryResponse.isLoading
          "
        />

        <ProjectImageCrop
          v-if="cropImage"
          :file="file"
          @save="handleSave"
          @cancel="handleCancel"
        />

        <div class="px-6 py-4 lg:pt-24 min-h-screen flex flex-col" v-else>
          <header class="font-semibold text-lg flex text-text-alternate-1 pr-6">
            <button @click="handlePrev">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto">Create Fundraiser</h2>
          </header>
          <form class="my-8" @submit.prevent="handleSubmit">
            <div
              class="flex flex-col my-3 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>Add Images</label>
                <BToolTipBtn :details="infoData.images" />
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  my-2
                  overflow-hidden
                  w-full
                "
              >
                <AttachmentIcon class="mx-5 h-6.5 fill-current" />
                <label
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    text-text-alternate-3
                    opacity-70
                  "
                  >Choose image (jpg, jpeg, png) (Max. 1MB)
                  <input
                    type="file"
                    hidden
                    accept="image/png,image/jpeg,image/jpg"
                    @change="handleFileInput"
                    :disabled="images.length === 3"
                  />
                </label>
              </div>
              <p class="font-medium text-text-alternate-3 text-xs">
                Add at least 1 image (You may add up to 3)
              </p>
              <span
                v-for="error in v$.images.$errors"
                :key="error.$uid"
                class="text-xs text-text-danger"
                >{{ error.$message }}</span
              >
              <p
                class="text-xs text-text-danger font-semibold"
                v-if="images.length < 1"
              >
                Please upload atleast 1 image
              </p>
            </div>
            <div class="overflow-x-auto py-1" v-if="images.length">
              <div class="inline-flex gap-3">
                <div
                  class="w-72 relative rounded-md overflow-hidden"
                  v-for="(link, index) of images"
                  :key="index"
                >
                  <button
                    class="
                      text-2xl
                      bg-bg-primary
                      rounded-full
                      overflow-hidden
                      h-5
                      w-5
                      absolute
                      z-10
                      right-4
                      top-4
                    "
                    @click.prevent="
                      link.isFeatured
                        ? showFeaturedImgRemovalError()
                        : removeImage(index)
                    "
                  >
                    <span class="inline-block transform -translate-y-1">
                      &times;
                    </span>
                  </button>
                  <img :src="link.image" alt="image" />

                  <div
                    class="
                      absolute
                      bottom-0
                      h-10
                      flex
                      justify-between
                      items-center
                      w-full
                      bg-bg-alternate-3
                      text-sm
                      font-medium
                      px-3
                      text-text-primary
                      bg-opacity-50
                    "
                  >
                    <p>Mark as featured Image</p>

                    <input
                      type="checkbox"
                      :checked="link.isFeatured"
                      :class="{
                        'bg-bg-primary pointer-events-none': link.isFeatured,
                      }"
                      @change="
                        link.isFeatured
                          ? null
                          : ((link.isFeatured = !link.isFeatured),
                            setFeaturedImage(index))
                      "
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="flex flex-col my-4 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label
                  >Add YouTube video link
                  <span class="font-medium">(optional)</span></label
                >
                <BToolTipBtn :details="infoData.youtube" />
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                "
              >
                <YTIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  placeholder="https://"
                  v-model="youTubeLink"
                />
              </div>
            </div>
            <div
              class="
                relative
                rounded-md
                h-48
                2xs:h-52
                xs:h-64
                md:h-72
                overflow-hidden
              "
              v-if="embededYoutubeId"
            >
              <button
                class="
                  text-2xl
                  bg-bg-primary
                  rounded-full
                  overflow-hidden
                  h-5
                  w-5
                  absolute
                  z-10
                  right-4
                  top-4
                "
                @click.prevent="youTubeLink = ''"
              >
                <span class="inline-block transform -translate-y-1">
                  &times;</span
                >
              </button>
              <iframe
                :src="`https://www.youtube.com/embed/${embededYoutubeId}`"
                width="100%"
                height="100%"
              />
            </div>

            <template v-if="isOneTime">
              <div
                class="
                  flex flex-col
                  my-4
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label>Are you the beneficiary of this fundraiser?</label>
                  <BToolTipBtn :details="infoData.isBeneficiary" />
                </div>
                <div class="flex gap-4">
                  <label
                    class="
                      border
                      flex-grow
                      rounded
                      border-border-primary
                      mt-2
                      mb-1
                      p-4
                      text-sm
                      font-semibold
                      focus:outline-none
                      input-shadow
                      flex
                      items-center
                    "
                  >
                    <input
                      class="mr-3"
                      type="radio"
                      v-model="isOrganiserBeneficiary"
                      :value="true"
                    />
                    <span>Yes</span>
                  </label>
                  <label
                    class="
                      border
                      flex-grow
                      rounded
                      border-border-primary
                      mt-2
                      mb-1
                      p-4
                      text-sm
                      font-semibold
                      focus:outline-none
                      input-shadow
                      flex
                      items-center
                    "
                  >
                    <input
                      class="mr-3"
                      type="radio"
                      v-model="isOrganiserBeneficiary"
                      :value="false"
                    />
                    <span>No</span>
                  </label>
                </div>
              </div>

              <template v-if="!isOrganiserBeneficiary">
                <div
                  class="
                    flex flex-col
                    my-4
                    text-text-primary
                    font-semibold
                    text-sm
                  "
                >
                  <div class="flex">
                    <label>Name of beneficiary</label>
                    <BToolTipBtn :details="infoData.beneficiaryName" />
                  </div>
                  <input
                    class="
                      border
                      rounded
                      border-border-primary
                      mt-2
                      mb-1
                      p-4
                      text-sm
                      font-semibold
                      focus:outline-none
                      input-shadow
                    "
                    placeholder="Full name"
                    type="text"
                    v-model.trim="beneficiary.fullname"
                  />
                </div>

                <div
                  class="
                    flex flex-col
                    my-4
                    text-text-primary
                    font-semibold
                    text-sm
                  "
                >
                  <div class="flex">
                    <label>Link to beneficiary</label>
                    <BToolTipBtn :details="infoData.beneficiaryLink" />
                  </div>
                  <input
                    class="
                      border
                      rounded
                      border-border-primary
                      mt-2
                      mb-1
                      p-4
                      text-sm
                      font-semibold
                      focus:outline-none
                      input-shadow
                    "
                    type="text"
                    placeholder="https://"
                    v-model.trim="beneficiary.link"
                  />
                  <p class="text-text-alternate-3 text-xs font-medium mt-1">
                    This could be a link to a public profile, article or post
                    about the beneficiary
                  </p>
                </div>
              </template>
            </template>

            <div
              class="flex flex-col my-5 text-text-primary font-semibold text-sm"
              v-if="isOneTime"
            >
              <div class="flex">
                <label>Invite Observers (Optional)</label>
                <BToolTipBtn :details="infoData.observers" />
              </div>
              <div class="my-2">
                <BFilterableSelectInput
                  v-model="memberUsername"
                  :seachMethod="searchUsers"
                  label="name"
                  v-slot="{ item }"
                >
                  <div
                    :class="[
                      'flex gap-2 items-center font-semibold py-2 xs:py-2.5 px-4 w-full cursor-pointer',
                      alreadySelected(item.userid)
                        ? 'bg-bg-alternate-3'
                        : 'hover:bg-bg-alternate-5',
                    ]"
                    @click="addMember(item)"
                  >
                    <span
                      class="
                        rounded-full
                        flex flex-shrink-0
                        justify-center
                        items-center
                        text-sm
                        h-8
                        w-8
                      "
                      :style="{
                        backgroundColor: randomColor({
                          luminosity: 'light',
                          seed: item.firstname[0],
                        }),
                      }"
                      >{{ item.firstname[0] }}</span
                    >

                    <span class="text-text-primary w-20 flex-grow truncate">
                      {{ item.firstname }} {{ item.lastname }}
                    </span>
                    <span class="text-text-alternate-3 flex-grow text-right"
                      >@{{ item.username }}</span
                    >
                  </div>
                </BFilterableSelectInput>
                <p class="text-text-alternate-3 text-xs font-medium mt-1">
                  Observers must accept your invitation before your fundraiser
                  is made public.
                </p>
              </div>

              <div v-if="members.length">
                <div
                  v-for="({ userdetail, memberType }, index) in members"
                  class="
                    flex
                    gap-2
                    xs:gap-3
                    rounded
                    justify-between
                    items-center
                    border border-border-primary
                    text-xs
                    px-4
                    py-2.5
                    xs:py-3
                    my-2
                    font-semibold
                  "
                  :key="`${userdetail.userid}-${index}`"
                >
                  <div
                    class="
                      rounded-full
                      bg-bg-alternate-3
                      text-sm
                      xs:text-base
                      h-8
                      xs:h-10
                      w-8
                      xs:w-10
                      flex flex-shrink-0
                      justify-center
                      items-center
                    "
                    :style="{
                      backgroundColor: randomColor({
                        luminosity: 'light',
                        seed: userdetail.firstname[0],
                      }),
                    }"
                  >
                    {{ userdetail.firstname[0] }}
                  </div>
                  <div class="w-full">
                    <p class="flex xs:text-sm">
                      <span
                        class="
                          text-text-primary
                          w-10
                          flex-grow
                          max-w-min
                          truncate
                        "
                        >{{ userdetail.firstname }}
                        {{ userdetail.lastname }}</span
                      >
                      <span class="ml-2 text-text-alternate-3 truncate"
                        >@{{ userdetail.username }}</span
                      >
                    </p>
                    <p class="text-text-alternate-4 mt-0.5 capitalize">
                      {{ memberType.toLowerCase() }}
                    </p>
                  </div>
                  <button
                    class="text-4xl h-6 overflow-hidden flex-shrink-0"
                    @click.prevent="removeMember(index)"
                  >
                    <span class="inline-block transform -translate-y-2.5">
                      &times;
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div
              class="flex flex-col my-5 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label
                  >Social Media Links
                  <span class="font-medium">(optional)</span></label
                >
                <BToolTipBtn :details="infoData.socials" />
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                  mt-2
                "
              >
                <FBIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  placeholder="https://"
                  v-model.trim="facebook"
                />
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                "
              >
                <IGIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  placeholder="https://"
                  v-model.trim="instagram"
                />
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                "
              >
                <TWIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  placeholder="https://"
                  v-model.trim="twitter"
                />
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                "
              >
                <YTIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  placeholder="https://"
                  v-model.trim="youtube"
                />
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  overflow-hidden
                  w-full
                  my-1
                "
              >
                <TKKIcon class="mx-3.5 h-6.5" />
                <input
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    focus:outline-none
                  "
                  type="text"
                  placeholder="https://"
                  v-model.trim="tiktok"
                />
              </div>
            </div>

            <hr class="text-text-alternate-8" />

            <div
              class="
                rounded
                bg-bg-alternate-3
                p-4
                my-4
                relative
                text-text-primary
              "
            >
              <div class="flex justify-between">
                <h3 class="font-semibold mb-2 text-sm xs:text-base">
                  Make Private
                </h3>
                <BToggle v-model="isPrivate" />
              </div>
              <p class="text-2xs xs:text-xs">
                Hide your fundraiser from the public. You can still share the
                link with anyone to recieve donations.
              </p>
            </div>

            <div
              class="
                rounded
                bg-bg-alternate-3
                p-4
                my-4
                relative
                text-text-primary
              "
            >
              <div class="flex justify-between">
                <h3 class="font-semibold mb-2 text-sm xs:text-base">
                  Turn On/Off Comments
                </h3>
                <BToggle v-model="canComment" />
              </div>
              <p class="text-2xs xs:text-xs">
                Turn on/off the ability for users to comment on your fundraiser.
              </p>
            </div>

            <div
              class="
                rounded
                bg-bg-alternate-3
                p-4
                my-4
                relative
                text-text-primary
              "
              v-if="!isOneTime"
            >
              <div class="flex justify-between items-center mb-4">
                <h3 class="font-semibold text-sm xs:text-base">
                  Monthly Donation
                </h3>
                <button
                  class="
                    focus:outline-none
                    border border-border-alternate-4
                    text-text-alternate-4
                    font-semibold
                    rounded-full
                    w-14
                    text-xs
                    py-0.5
                  "
                  @click.prevent="handleMonthlyButton"
                >
                  {{ canEdit ? 'Save' : 'Edit' }}
                </button>
              </div>
              <p class="text-2xs xs:text-xs mt-2 mb-8">
                Solicit monthly donations from your donors by specifying
                subscription amounts. E.g. N1,000/Month
              </p>
              <div
                class="
                  flex flex-col
                  my-3
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <label class="text-xs">Silver Donor</label>
                <div class="flex my-2 w-full items-center">
                  <input
                    :class="[
                      'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                      { 'bg-bg-alternate-3': !canEdit },
                    ]"
                    :readonly="!canEdit"
                    type="number"
                    v-model.number="silverDonor"
                    pattern="\d*"
                    inputmode="decimal"
                    min="0"
                  />
                  <p class="ml-2 whitespace-nowrap">Per Month</p>
                </div>
              </div>
              <div
                class="
                  flex flex-col
                  my-3
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <label class="text-xs">Gold Donor</label>
                <div class="flex my-2 w-full items-center">
                  <input
                    :class="[
                      'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                      { 'bg-bg-alternate-3': !canEdit },
                    ]"
                    :readonly="!canEdit"
                    type="number"
                    v-model.number="goldDonor"
                    pattern="\d*"
                    inputmode="decimal"
                    min="0"
                  />
                  <p class="ml-2 whitespace-nowrap">Per Month</p>
                </div>
              </div>
              <div
                class="
                  flex flex-col
                  my-3
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <label class="text-xs">Diamond Donor</label>
                <div class="flex my-2 w-full items-center">
                  <input
                    :class="[
                      'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                      { 'bg-bg-alternate-3': !canEdit },
                    ]"
                    :readonly="!canEdit"
                    type="number"
                    v-model.number="diamondDonor"
                    pattern="\d*"
                    inputmode="decimal"
                    min="0"
                  />
                  <p class="ml-2 whitespace-nowrap">Per Month</p>
                </div>
              </div>
            </div>

            <div
              class="
                rounded
                bg-bg-alternate-3
                p-4
                my-4
                relative
                text-text-primary
              "
            >
              <div class="flex justify-between">
                <div class="flex mb-2">
                  <h3 class="font-semibold text-sm xs:text-base">
                    Allow Promote and Earn
                  </h3>
                  <BToolTipBtn :details="infoData.promoteEarn" />
                </div>
                <BToggle v-model="allowPromoteAndEarn" />
              </div>
              <p class="text-2xs xs:text-xs">
                Allow people promote your fundraiser within their networks and
                earn commission on the amount they were able to raise.
              </p>
            </div>

            <div v-if="allowPromoteAndEarn">
              <div
                class="
                  flex flex-col
                  my-3
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label>Commission percentage (%)</label>
                  <BToolTipBtn :details="infoData.commissionPercentage" />
                </div>
                <input
                  class="rounded h-1 border border-border-primary slider my-6"
                  type="range"
                  placeholder="20"
                  v-model.number="promoteAndEarnCommPercent"
                  min="20"
                  max="50"
                  pattern="\d*"
                  inputmode="decimal"
                />
                <span
                  class="
                    rounded
                    border border-border-primary
                    px-4
                    py-3.5
                    min-w-36
                    max-w-min
                    font-medium
                    mb-3
                  "
                >
                  {{ promoteAndEarnCommPercent }}%
                </span>
                <p class="font-medium text-text-alternate-3 text-xs">
                  Specify your Promote and Earn Commission percentage, should be
                  between 20 and 50%
                </p>
              </div>
            </div>

            <div v-if="isRewarding">
              <div
                class="
                  flex flex-col
                  my-3
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <label>Blessing Amount</label>
                <input
                  class="
                    border
                    rounded
                    border-border-primary
                    my-2
                    p-4
                    text-sm
                    font-medium
                    focus:outline-none
                  "
                  type="number"
                  placeholder="0.0"
                  v-model.number="amount"
                  pattern="\d*"
                  inputmode="decimal"
                  min="0"
                />
                <p class="font-medium text-text-alternate-3 text-xs">
                  Enter a maximum amount for this reward
                </p>
              </div>
              <div
                class="
                  flex flex-col
                  mt-6
                  mb-3
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <label>Reward</label>
                <textarea
                  class="
                    border
                    rounded
                    border-border-primary
                    my-2
                    p-4
                    text-sm
                    font-medium
                    focus:outline-none
                  "
                  v-model="reward"
                />
              </div>
              <button
                class="
                  bg-btn-bg-alternate-1
                  text-btn-text-alternate-1
                  w-full
                  py-4
                  rounded
                  text-center text-sm
                  xs:text-base
                  font-bold
                "
                @click.prevent="handleReward"
                :disabled="!amount || !reward"
              >
                Add Reward
              </button>

              <hr class="text-text-alternate-8 my-6" />

              <div v-if="rewards.length">
                <div
                  class="
                    rounded
                    border border-border-alternate-1
                    p-2
                    xs:py-4
                    my-2
                    relative
                    text-text-primary
                  "
                  v-for="(rewardValue, index) in rewards"
                  :key="`${index}-${rewardValue.amount}`"
                >
                  <div class="flex justify-between items-center mb-2 xs:pr-2">
                    <span
                      class="font-semibold text-xs xs:text-sm pl-2"
                      style="color: #577387"
                    >
                      ₦{{ rewardValue.amount.toLocaleString() }} or more
                    </span>
                    <button
                      class="text-3xl overflow-hidden h-4"
                      @click.prevent="removeReward(index)"
                    >
                      <span class="inline-block transform -translate-y-3.5">
                        &times;
                      </span>
                    </button>
                  </div>
                  <p
                    class="
                      text-2xs
                      xs:text-xs
                      text-text-primary
                      font-medium
                      px-2
                    "
                  >
                    {{ rewardValue.reward }}
                  </p>
                </div>

                <hr class="text-text-alternate-8 my-5" />
              </div>
            </div>

            <hr class="text-text-alternate-8" />
            <div
              class="
                
                rounded
                bg-bg-alternate-3
                p-4
                my-4
                relative
                text-text-primary
              "
            >
              <div class="flex justify-between">
                <div class="flex mb-2">
                  <h3 class="font-semibold text-sm xs:text-base">
                    Request Media Package
                  </h3>
                  <BToolTipBtn :details="infoData.advertPromo" />
                </div>
                <BToggle v-model="requestAdvertPromo" />
              </div>
              <p class="text-2xs xs:text-xs">
                Blessmi Media Package allows Blessmi to advertise and promote your fundraiser through various media platforms, in return for a special Media Package Commission.
              </p>
            </div>

            

            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                rounded
                w-full
                py-4
                mt-2
                font-bold
                text-sm
                xs:text-base
              "
              type="submit"
            >
              Create Fundraiser
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24">
        <div>
          <BSpinner
            v-if="
              isCompressing ||
              fundraiserResponse.isLoading ||
              galleryResponse.isLoading
            "
          />

          <ProjectImageCrop
            v-if="cropImage"
            :file="file"
            @save="handleSave"
            @cancel="handleCancel"
          />

          <div class="px-26 py-4 pt-8 min-h-screen flex flex-col" v-else>
            <header
              class="font-semibold text-lg flex text-text-alternate-1 pr-6"
            >
              <button @click="handlePrev">
                <LeftArrowIcon class="h-6 fill-current" />
              </button>
              <h2 class="mx-auto">Create Fundraiser</h2>
            </header>
            <form class="my-8" @submit.prevent="handleSubmit">
              <div
                class="
                  flex flex-col
                  my-3
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label>Add Images</label>
                  <BToolTipBtn :details="infoData.images" />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    my-2
                    overflow-hidden
                    w-full
                  "
                >
                  <AttachmentIcon class="mx-5 h-6.5 fill-current" />
                  <label
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      text-text-alternate-3
                      opacity-70
                    "
                    >Choose image (jpg, jpeg, png) (Max. 1MB)
                    <input
                      type="file"
                      hidden
                      accept="image/png,image/jpeg,image/jpg"
                      @change="handleFileInput"
                      :disabled="images.length === 3"
                    />
                  </label>
                </div>
                <p class="font-medium text-text-alternate-3 text-xs">
                  Add at least 1 image (You may add up to 3)
                </p>
                <span
                  v-for="error in v$.images.$errors"
                  :key="error.$uid"
                  class="text-xs text-text-danger"
                  >{{ error.$message }}</span
                >
                <p
                  class="text-xs text-text-danger font-semibold"
                  v-if="images.length < 1"
                >
                  Please upload atleast 1 image
                </p>
              </div>
              <div class="overflow-x-auto py-1" v-if="images.length">
                <div class="inline-flex gap-3">
                  <div
                    class="w-72 relative rounded-md overflow-hidden"
                    v-for="(link, index) of images"
                    :key="index"
                  >
                    <button
                      class="
                        text-2xl
                        bg-bg-primary
                        rounded-full
                        overflow-hidden
                        h-5
                        w-5
                        absolute
                        z-10
                        right-4
                        top-4
                      "
                      @click.prevent="
                        link.isFeatured
                          ? showFeaturedImgRemovalError()
                          : removeImage(index)
                      "
                    >
                      <span class="inline-block transform -translate-y-1">
                        &times;
                      </span>
                    </button>
                    <img :src="link.image" alt="image" />
                    <div
                      class="
                        absolute
                        bottom-0
                        h-10
                        flex
                        justify-between
                        items-center
                        w-full
                        bg-bg-alternate-3
                        text-sm
                        font-medium
                        px-3
                        text-text-primary
                        bg-opacity-50
                      "
                    >
                      <p>Mark as featured Image</p>

                      <input
                        type="checkbox"
                        :checked="link.isFeatured"
                        :class="{
                          'bg-bg-primary pointer-events-none': link.isFeatured,
                        }"
                        @change="
                          link.isFeatured
                            ? null
                            : ((link.isFeatured = !link.isFeatured),
                              setFeaturedImage(index))
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="
                  flex flex-col
                  my-4
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label
                    >Add YouTube video link
                    <span class="font-medium">(optional)</span></label
                  >
                  <BToolTipBtn :details="infoData.youtube" />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <YTIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="https://"
                    v-model="youTubeLink"
                  />
                </div>
              </div>
              <div
                class="
                  relative
                  rounded-md
                  h-48
                  2xs:h-52
                  xs:h-64
                  md:h-72
                  overflow-hidden
                "
                v-if="embededYoutubeId"
              >
                <button
                  class="
                    text-2xl
                    bg-bg-primary
                    rounded-full
                    overflow-hidden
                    h-5
                    w-5
                    absolute
                    z-10
                    right-4
                    top-4
                  "
                  @click.prevent="youTubeLink = ''"
                >
                  <span class="inline-block transform -translate-y-1">
                    &times;</span
                  >
                </button>
                <iframe
                  :src="`https://www.youtube.com/embed/${embededYoutubeId}`"
                  width="100%"
                  height="100%"
                />
              </div>

              <template v-if="isOneTime">
                <div
                  class="
                    flex flex-col
                    my-4
                    text-text-primary
                    font-semibold
                    text-sm
                  "
                >
                  <div class="flex">
                    <label>Are you the beneficiary of this fundraiser?</label>
                    <BToolTipBtn :details="infoData.isBeneficiary" />
                  </div>
                  <div class="flex gap-4">
                    <label
                      class="
                        border
                        flex-grow
                        rounded
                        border-border-primary
                        mt-2
                        mb-1
                        p-4
                        text-sm
                        font-semibold
                        focus:outline-none
                        input-shadow
                        flex
                        items-center
                      "
                    >
                      <input
                        class="mr-3"
                        type="radio"
                        v-model="isOrganiserBeneficiary"
                        :value="true"
                      />
                      <span>Yes</span>
                    </label>
                    <label
                      class="
                        border
                        flex-grow
                        rounded
                        border-border-primary
                        mt-2
                        mb-1
                        p-4
                        text-sm
                        font-semibold
                        focus:outline-none
                        input-shadow
                        flex
                        items-center
                      "
                    >
                      <input
                        class="mr-3"
                        type="radio"
                        v-model="isOrganiserBeneficiary"
                        :value="false"
                      />
                      <span>No</span>
                    </label>
                  </div>
                </div>

                <template v-if="!isOrganiserBeneficiary">
                  <div
                    class="
                      flex flex-col
                      my-4
                      text-text-primary
                      font-semibold
                      text-sm
                    "
                  >
                    <div class="flex">
                      <label>Name of beneficiary</label>
                      <BToolTipBtn :details="infoData.beneficiaryName" />
                    </div>
                    <input
                      class="
                        border
                        rounded
                        border-border-primary
                        mt-2
                        mb-1
                        p-4
                        text-sm
                        font-semibold
                        focus:outline-none
                        input-shadow
                      "
                      placeholder="Full name"
                      type="text"
                      v-model.trim="beneficiary.fullname"
                    />
                  </div>

                  <div
                    class="
                      flex flex-col
                      my-4
                      text-text-primary
                      font-semibold
                      text-sm
                    "
                  >
                    <div class="flex">
                      <label>Link to beneficiary</label>
                      <BToolTipBtn :details="infoData.beneficiaryLink" />
                    </div>
                    <input
                      class="
                        border
                        rounded
                        border-border-primary
                        mt-2
                        mb-1
                        p-4
                        text-sm
                        font-semibold
                        focus:outline-none
                        input-shadow
                      "
                      type="text"
                      placeholder="https://"
                      v-model.trim="beneficiary.link"
                    />
                    <p class="text-text-alternate-3 text-xs font-medium mt-1">
                      This could be a link to a public profile, article or post
                      about the beneficiary
                    </p>
                  </div>
                </template>
              </template>

              <div
                class="
                  flex flex-col
                  my-5
                  text-text-primary
                  font-semibold
                  text-sm
                "
                v-if="isOneTime"
              >
                <div class="flex">
                  <label>Invite Observers (Optional)</label>
                  <BToolTipBtn :details="infoData.observers" />
                </div>
                <div class="my-2">
                  <BFilterableSelectInput
                    v-model="memberUsername"
                    :seachMethod="searchUsers"
                    label="name"
                    v-slot="{ item }"
                  >
                    <div
                      :class="[
                        'flex gap-2 items-center font-semibold py-2 xs:py-2.5 px-4 w-full cursor-pointer',
                        alreadySelected(item.userid)
                          ? 'bg-bg-alternate-3'
                          : 'hover:bg-bg-alternate-5',
                      ]"
                      @click="addMember(item)"
                    >
                      <span
                        class="
                          rounded-full
                          flex flex-shrink-0
                          justify-center
                          items-center
                          text-sm
                          h-8
                          w-8
                        "
                        :style="{
                          backgroundColor: randomColor({
                            luminosity: 'light',
                            seed: item.firstname[0],
                          }),
                        }"
                        >{{ item.firstname[0] }}</span
                      >

                      <span class="text-text-primary w-20 flex-grow truncate">
                        {{ item.firstname }} {{ item.lastname }}
                      </span>
                      <span class="text-text-alternate-3 flex-grow text-right"
                        >@{{ item.username }}</span
                      >
                    </div>
                  </BFilterableSelectInput>
                  <p class="text-text-alternate-3 text-xs font-medium mt-1">
                    Observers must accept your invitation before your fundraiser
                    is made public.
                  </p>
                </div>

                <div v-if="members.length">
                  <div
                    v-for="({ userdetail, memberType }, index) in members"
                    class="
                      flex
                      gap-2
                      xs:gap-3
                      rounded
                      justify-between
                      items-center
                      border border-border-primary
                      text-xs
                      px-4
                      py-2.5
                      xs:py-3
                      my-2
                      font-semibold
                    "
                    :key="`${userdetail.userid}-${index}`"
                  >
                    <div
                      class="
                        rounded-full
                        bg-bg-alternate-3
                        text-sm
                        xs:text-base
                        h-8
                        xs:h-10
                        w-8
                        xs:w-10
                        flex flex-shrink-0
                        justify-center
                        items-center
                      "
                      :style="{
                        backgroundColor: randomColor({
                          luminosity: 'light',
                          seed: userdetail.firstname[0],
                        }),
                      }"
                    >
                      {{ userdetail.firstname[0] }}
                    </div>
                    <div class="w-full">
                      <p class="flex xs:text-sm">
                        <span
                          class="
                            text-text-primary
                            w-10
                            flex-grow
                            max-w-min
                            truncate
                          "
                          >{{ userdetail.firstname }}
                          {{ userdetail.lastname }}</span
                        >
                        <span class="ml-2 text-text-alternate-3 truncate"
                          >@{{ userdetail.username }}</span
                        >
                      </p>
                      <p class="text-text-alternate-4 mt-0.5 capitalize">
                        {{ memberType.toLowerCase() }}
                      </p>
                    </div>
                    <button
                      class="text-4xl h-6 overflow-hidden flex-shrink-0"
                      @click.prevent="removeMember(index)"
                    >
                      <span class="inline-block transform -translate-y-2.5">
                        &times;
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div
                class="
                  flex flex-col
                  my-5
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label
                    >Social Media Links
                    <span class="font-medium">(optional)</span></label
                  >
                  <BToolTipBtn :details="infoData.socials" />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                    mt-2
                  "
                >
                  <FBIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="https://"
                    v-model.trim="facebook"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <IGIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="https://"
                    v-model.trim="instagram"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <TWIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="https://"
                    v-model.trim="twitter"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <YTIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="https://"
                    v-model.trim="youtube"
                  />
                </div>
                <div
                  class="
                    border
                    rounded
                    border-border-primary
                    flex
                    items-center
                    overflow-hidden
                    w-full
                    my-1
                  "
                >
                  <TKKIcon class="mx-3.5 h-6.5" />
                  <input
                    class="
                      p-4
                      w-full
                      border-l border-border-primary
                      text-sm
                      font-semibold
                      focus:outline-none
                    "
                    type="text"
                    placeholder="https://"
                    v-model.trim="tiktok"
                  />
                </div>
              </div>

              <hr class="text-text-alternate-8" />

              <div
                class="
                  rounded
                  bg-bg-alternate-3
                  p-4
                  my-4
                  relative
                  text-text-primary
                "
              >
                <div class="flex justify-between">
                  <h3 class="font-semibold mb-2 text-sm xs:text-base">
                    Make Private
                  </h3>
                  <BToggle v-model="isPrivate" />
                </div>
                <p class="text-2xs xs:text-xs">
                  Hide your fundraiser from the public. You can still share the
                  link with anyone to recieve donations.
                </p>
              </div>

              <div
                class="
                  rounded
                  bg-bg-alternate-3
                  p-4
                  my-4
                  relative
                  text-text-primary
                "
              >
                <div class="flex justify-between">
                  <h3 class="font-semibold mb-2 text-sm xs:text-base">
                    Turn On/Off Comments
                  </h3>
                  <BToggle v-model="canComment" />
                </div>
                <p class="text-2xs xs:text-xs">
                  Turn on/off the ability for users to comment on your
                  fundraiser.
                </p>
              </div>

              <div
                class="
                  rounded
                  bg-bg-alternate-3
                  p-4
                  my-4
                  relative
                  text-text-primary
                "
                v-if="!isOneTime"
              >
                <div class="flex justify-between items-center mb-4">
                  <h3 class="font-semibold text-sm xs:text-base">
                    Monthly Donation
                  </h3>
                  <button
                    class="
                      focus:outline-none
                      border border-border-alternate-4
                      text-text-alternate-4
                      font-semibold
                      rounded-full
                      w-14
                      text-xs
                      py-0.5
                    "
                    @click.prevent="handleMonthlyButton"
                  >
                    {{ canEdit ? 'Save' : 'Edit' }}
                  </button>
                </div>
                <p class="text-2xs xs:text-xs mt-2 mb-8">
                  Solicit monthly donations from your donors by specifying
                  subscription amounts. E.g. N1,000/Month
                </p>
                <div
                  class="
                    flex flex-col
                    my-3
                    text-text-primary
                    font-semibold
                    text-sm
                  "
                >
                  <label class="text-xs">Silver Donor</label>
                  <div class="flex my-2 w-full items-center">
                    <input
                      :class="[
                        'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                        { 'bg-bg-alternate-3': !canEdit },
                      ]"
                      :readonly="!canEdit"
                      type="number"
                      v-model.number="silverDonor"
                      pattern="\d*"
                      inputmode="decimal"
                      min="0"
                    />
                    <p class="ml-2 whitespace-nowrap">Per Month</p>
                  </div>
                </div>
                <div
                  class="
                    flex flex-col
                    my-3
                    text-text-primary
                    font-semibold
                    text-sm
                  "
                >
                  <label class="text-xs">Gold Donor</label>
                  <div class="flex my-2 w-full items-center">
                    <input
                      :class="[
                        'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                        { 'bg-bg-alternate-3': !canEdit },
                      ]"
                      :readonly="!canEdit"
                      type="number"
                      v-model.number="goldDonor"
                      pattern="\d*"
                      inputmode="decimal"
                      min="0"
                    />
                    <p class="ml-2 whitespace-nowrap">Per Month</p>
                  </div>
                </div>
                <div
                  class="
                    flex flex-col
                    my-3
                    text-text-primary
                    font-semibold
                    text-sm
                  "
                >
                  <label class="text-xs">Diamond Donor</label>
                  <div class="flex my-2 w-full items-center">
                    <input
                      :class="[
                        'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                        { 'bg-bg-alternate-3': !canEdit },
                      ]"
                      :readonly="!canEdit"
                      type="number"
                      v-model.number="diamondDonor"
                      pattern="\d*"
                      inputmode="decimal"
                      min="0"
                    />
                    <p class="ml-2 whitespace-nowrap">Per Month</p>
                  </div>
                </div>
              </div>

              <div
                class="
                  rounded
                  bg-bg-alternate-3
                  p-4
                  my-4
                  relative
                  text-text-primary
                "
              >
                <div class="flex justify-between">
                  <div class="flex mb-2">
                    <h3 class="font-semibold text-sm xs:text-base">
                      Allow Promote and Earn
                    </h3>
                    <BToolTipBtn :details="infoData.promoteEarn" />
                  </div>
                  <BToggle v-model="allowPromoteAndEarn" />
                </div>
                <p class="text-2xs xs:text-xs">
                  Give back to people who donate to your fundraiser as a show of
                  appreciation and encouragement.
                </p>
              </div>

              <div v-if="allowPromoteAndEarn">
                <div
                  class="
                    flex flex-col
                    my-3
                    text-text-primary
                    font-semibold
                    text-sm
                  "
                >
                  <div class="flex">
                    <label>Commission percentage (%)</label>
                    <BToolTipBtn :details="infoData.commissionPercentage" />
                  </div>
                  <input
                    class="rounded h-1 border border-border-primary slider my-6"
                    type="range"
                    placeholder="20"
                    v-model.number="promoteAndEarnCommPercent"
                    min="20"
                    max="50"
                    pattern="\d*"
                    inputmode="decimal"
                  />
                  <span
                    class="
                      rounded
                      border border-border-primary
                      px-4
                      py-3.5
                      min-w-36
                      max-w-min
                      font-medium
                      mb-3
                    "
                  >
                    {{ promoteAndEarnCommPercent }}%
                  </span>
                  <p class="font-medium text-text-alternate-3 text-xs">
                    Specify your Promote and Earn Commission percentage, should
                    be between 20 and 50%
                  </p>
                </div>
              </div>

              <div v-if="isRewarding">
                <div
                  class="
                    flex flex-col
                    my-3
                    text-text-primary
                    font-semibold
                    text-sm
                  "
                >
                  <label>Blessing Amount</label>
                  <input
                    class="
                      border
                      rounded
                      border-border-primary
                      my-2
                      p-4
                      text-sm
                      font-medium
                      focus:outline-none
                    "
                    type="number"
                    placeholder="0.0"
                    v-model.number="amount"
                    pattern="\d*"
                    inputmode="decimal"
                    min="0"
                  />
                  <p class="font-medium text-text-alternate-3 text-xs">
                    Enter a maximum amount for this reward
                  </p>
                </div>
                <div
                  class="
                    flex flex-col
                    mt-6
                    mb-3
                    text-text-primary
                    font-semibold
                    text-sm
                  "
                >
                  <label>Reward</label>
                  <textarea
                    class="
                      border
                      rounded
                      border-border-primary
                      my-2
                      p-4
                      text-sm
                      font-medium
                      focus:outline-none
                    "
                    v-model="reward"
                  />
                </div>
                <button
                  class="
                    bg-btn-bg-alternate-1
                    text-btn-text-alternate-1
                    w-full
                    py-4
                    rounded
                    text-center text-sm
                    xs:text-base
                    font-bold
                  "
                  @click.prevent="handleReward"
                  :disabled="!amount || !reward"
                >
                  Add Reward
                </button>

                <hr class="text-text-alternate-8 my-6" />

                <div v-if="rewards.length">
                  <div
                    class="
                      rounded
                      border border-border-alternate-1
                      p-2
                      xs:py-4
                      my-2
                      relative
                      text-text-primary
                    "
                    v-for="(rewardValue, index) in rewards"
                    :key="`${index}-${rewardValue.amount}`"
                  >
                    <div class="flex justify-between items-center mb-2 xs:pr-2">
                      <span
                        class="font-semibold text-xs xs:text-sm pl-2"
                        style="color: #577387"
                      >
                        ₦{{ rewardValue.amount.toLocaleString() }} or more
                      </span>
                      <button
                        class="text-3xl overflow-hidden h-4"
                        @click.prevent="removeReward(index)"
                      >
                        <span class="inline-block transform -translate-y-3.5">
                          &times;
                        </span>
                      </button>
                    </div>
                    <p
                      class="
                        text-2xs
                        xs:text-xs
                        text-text-primary
                        font-medium
                        px-2
                      "
                    >
                      {{ rewardValue.reward }}
                    </p>
                  </div>

                  <hr class="text-text-alternate-8 my-5" />
                </div>
              </div>

              <div
                class="
                  border
                  rounded
                  bg-bg-alternate-3
                  p-4
                  my-4
                  relative
                  text-text-primary
                "
              >
                <div class="flex justify-between">
                  <div class="flex mb-2">
                    <h3 class="font-semibold text-sm xs:text-base">
                      Request Media Package
                    </h3>
                    <BToolTipBtn :details="infoData.advertPromo" />
                  </div>
                  <BToggle v-model="requestAdvertPromo" />
                </div>
                <p class="text-2xs xs:text-xs">
                  Blessmi Media Package allows Blessmi to advertise and promote your fundraiser through various media platforms, in return for a special Media Package Commission.
                </p>
              </div>

              <button
                class="
                  bg-btn-bg-primary
                  text-btn-text-primary
                  rounded
                  w-full
                  py-4
                  mt-2
                  font-bold
                  text-sm
                  xs:text-base
                "
                type="submit"
              >
                Create Fundraiser
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref, computed, watch } from 'vue';
import BFilterableSelectInput from '@/components/ui/BFilterableSelectInput';
import BToggle from '@/components/ui/BToggle';
import ProjectImageCrop from '../ProjectImageCrop';
import { getProjectData, getYouTubeVideoId } from '@/utils';
import { useApi } from '@/cmp-functions/useApi';
import { postFundraiser, postAnImage, searchUsers } from '@/services/api';
import { useStore } from 'vuex';
import BSpinner from '@/components/ui/BSpinner';
import randomColor from 'randomcolor';
import imageCompression from 'browser-image-compression';
import infoData from '@/data/info.js';
import BToolTipBtn from '@/components/ui/BToolTipBtn';
import { useToast } from 'vue-toastification';
import useVuelidate from '@vuelidate/core';
import { required, helpers, minLength } from '@vuelidate/validators';
import Swal from 'sweetalert2';

const data = {
  images: [],
  canComment: false,
  rewards: [],
  isPrivate: false,
  isOrganiserBeneficiary: true,
  allowPromoteAndEarn: true,
  requestAdvertPromo: false,
  promoteAndEarnCommPercent: 30,
};
const notRequired = {
  facebook: '',
  instagram: '',
  twitter: '',
  youtube: '',
  tiktok: '',
  youTubeLink: '',
  beneficiary: { fullname: '', link: '' },
};
const oneOffData = { members: [] };
const recurringData = {
  monthlyDonations: [
    {
      donationtype: 'SILVER',
      amount: 1000,
    },
    {
      donationtype: 'GOLD',
      amount: 2000,
    },
    {
      donationtype: 'DIAMOND',
      amount: 5000,
    },
  ],
};

export default {
  name: 'ProjectDetailsTwo',

  emits: ['next', 'prev'],

  props: ['project'],

  components: {
    BFilterableSelectInput,
    ProjectImageCrop,
    BToggle,
    BSpinner,
    BToolTipBtn,
  },

  setup(props, { emit }) {
    const cropImage = ref(false);
    const store = useStore();
    const authUser = store.state.auth.authData;
    const isCompressing = ref(false);
    const imageErrorMsg = ref('');
    const toast = useToast();

    const toggleCropImage = () => {
      cropImage.value = !cropImage.value;
    };

    const isOneTime = props.project.fundraiserType !== 'RECURRING';

    const initFormInputs = {
      ...data,
      ...(isOneTime ? oneOffData : recurringData),
    };
    const required$ = helpers.withMessage('This field is required', required);
    const formInputs = reactive({
      ...Object.keys(initFormInputs).reduce(
        (acc, next) => ({
          ...acc,
          [next]:
            props.project[next] !== undefined
              ? props.project[next]
              : initFormInputs[next],
        }),
        {}
      ),
      ...Object.keys(notRequired).reduce(
        (acc, next) => ({
          ...acc,
          [next]:
            props.project[next] !== undefined
              ? props.project[next]
              : notRequired[next],
        }),
        {}
      ),
    });
    const blessingForm = reactive({
      amount: '',

      reward: '',
      file: '',
      memberUsername: '',
      canEdit: false,

      isRewarding: props.project.rewards?.length > 0,
      silverDonor: 1000,
      goldDonor: 5000,
      diamondDonor: 10000,
    });

    const model = {
      images: formInputs.images,
    };
    const rules = {
      images: { required$, minLength: minLength(1), $each: { required } },
    };

    const v$ = useVuelidate(rules, model);

    watch(blessingForm, ({ isRewarding }) => {
      if (!isRewarding) {
        formInputs.rewards = [];
      }
    });

    watch(formInputs, ({ requestAdvertPromo }) => {
      if (requestAdvertPromo) {
        Swal.fire({
          title: `<p class="text-lg">Request Media Package</p>`,
          text: `Requesting this media package means that you commit to making yourself available for video and audio interviews and adverts. Your fundraiser may be put on hold until all media recordings are completed. And you agree to all terms and conditions associated with this scheme.`,
          // icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        }).then((result) => {
          if (!result.isConfirmed) {
            formInputs.requestAdvertPromo = false
          }
        });
      }
    });

    const [fundraiserResponse, createFundraiser] = useApi(postFundraiser);
    const [galleryResponse, postGallery] = useApi(postAnImage);

    watch(fundraiserResponse, ({ isSuccess, data }) => {
      if (isSuccess) {
        emit('next', { ...formInputs, id: data.id });
      }
    });

    watch(galleryResponse, ({ isSuccess, isFailure, data, error }) => {
      if (isSuccess) {
        formInputs.images.push({
          image: data.imageurl,
          isFeatured: formInputs.images.length ? false : true,
        });
        blessingForm.file = null;
        toggleCropImage();
      } else if (isFailure) {
        toast.error(error);
      }
    });

    const handleSave = async (image) => {
      const imageSize = Math.round(
        Buffer.from(image.substring(image.indexOf(',') + 1)).length / 1e3
      );
      if (imageSize > 1000) {
        toast.error(
          'Image size is too large, Maximum image size should be 1MB.'
        );
      } else {
        const { name } = blessingForm.file;
        postGallery({ file: { name, mimetype: 'image/jpeg', base64: image } });
      }
    };

    const handleSubmit = async () => {
      const result = await v$.value.$validate();
      if (!result || formInputs.images?.length < 1) {
        document.getElementById('form').scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }

      const payload = getProjectData(
        { ...props.project, ...formInputs },
        authUser.id
      );
      createFundraiser(payload);
    };

    const handlePrev = () => {
      emit('prev', formInputs);
    };

    const handleMonthlyButton = () => {
      const { silverDonor, goldDonor, diamondDonor, canEdit } = blessingForm;
      if (canEdit) {
        formInputs.monthlyDonations = [
          { donationtype: 'SILVER', amount: silverDonor },
          { donationtype: 'GOLD', amount: goldDonor },
          { donationtype: 'DIAMOND', amount: diamondDonor },
        ];
      }

      blessingForm.canEdit = !blessingForm.canEdit;
    };

    const validateFileInput = async (target) => {
      let file = target.files[0];
      let image = new Image();
      let reader = new FileReader();
      let width, height;
      reader.onload = async (e) => {
        image.src = e.target.result;

        image.onload = () => {
          width = image.width;
          height = image.height;

          if (image.width < 630 || image.height < 1200) {
            toast.error(
              'Image resolution is too low, image size should be atleast 630 x 1200px'
            );
            imageErrorMsg.value =
              'Image resolution is too low, image size should be atleast 630 x 1200px';
          } else {
            imageErrorMsg.value = '';
          }
        };
      };

      reader.readAsDataURL(file);

      console.log('Image Width: ', width);
      console.log('Image Height: ', height);
      console.log('Image error: ', imageErrorMsg.value);

      if (imageErrorMsg.value == '') {
        return true;
      } else {
        return false;
      }
    };

    const handleFileInput = async ({ target }) => {
      // const check = await validateFileInput(target);
      // console.log('This is check: ', check);

      // if (!check) {
      //   return;
      // } else {
      // }
      try {
        isCompressing.value = true;
        let file = target.files[0];
        blessingForm.file = await imageCompression(file, { maxSizeMB: 0.05 });
        toggleCropImage();
      } catch (error) {
        console.log(error);
      } finally {
        isCompressing.value = false;
      }
    };

    const handleReward = () => {
      const { amount, reward } = blessingForm;
      formInputs.rewards = [...formInputs.rewards, { amount, reward }];
      blessingForm.amount = '';
      blessingForm.reward = '';
    };

    const removeReward = (index) => {
      formInputs.rewards = formInputs.rewards.filter(
        (rwd, ind) => ind !== index
      );
    };

    const removeMember = (index) => {
      formInputs.members = formInputs.members.filter((_, ind) => index !== ind);
    };

    const addMember = (userdetail) => {
      if (alreadySelected(userdetail.userid)) return;

      formInputs.members = [
        ...formInputs.members,
        { userdetail, memberType: 'OBSERVER' },
      ];
      blessingForm.memberUsername = '';
    };

    const handleCancel = () => {
      blessingForm.file = null;
      toggleCropImage();
    };

    const removeImage = (index) => {
      formInputs.images = formInputs.images.filter((img, ind) => ind !== index);
    };
    const setFeaturedImage = (index) => {
      data.images.forEach((image, ind) => {
        image.isFeatured = false;

        if (ind === index) {
          image.isFeatured = true;
        }
      });
    };

    const showFeaturedImgRemovalError = () => {
      toast.error('You cannot remove the Featured Image');
    };

    const isFilled = computed(
      () =>
        Object.keys(initFormInputs).every(
          (key) =>
            formInputs[key] !== null &&
            formInputs[key] !== '' &&
            formInputs.images.length > 0
        ) &&
        !blessingForm.canEdit &&
        (blessingForm.isRewarding ? formInputs.rewards.length > 0 : true) &&
        (formInputs.isOrganiserBeneficiary ||
          (formInputs.beneficiary.fullname && formInputs.beneficiary.link))
    );

    const alreadySelected = (id) => {
      return formInputs.members.some(
        ({ userdetail }) => userdetail.userid == id
      );
    };

    const embededYoutubeId = computed(() =>
      getYouTubeVideoId(formInputs.youTubeLink)
    );

    return {
      ...toRefs(formInputs),
      ...toRefs(blessingForm),
      embededYoutubeId,
      isCompressing,
      alreadySelected,
      cropImage,
      toggleCropImage,
      isOneTime,
      searchUsers,
      randomColor,
      isFilled,
      removeImage,
      handleSubmit,
      handleMonthlyButton,
      fundraiserResponse,
      galleryResponse,
      handleSave,
      handleCancel,
      removeReward,
      removeMember,
      addMember,
      handleReward,
      handleFileInput,
      handlePrev,
      imageErrorMsg,
      infoData,
      validateFileInput,
      setFeaturedImage,
      v$,
      showFeaturedImgRemovalError,
    };
  },
};
</script>
