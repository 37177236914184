<template>
  <div id="form">
    <div class="lg:hidden">
      <div class="px-6 py-4 lg:pt-24">
        <header class="font-semibold text-lg flex text-text-alternate-1 pr-6">
          <button @click="handlePrev">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <h2 class="mx-auto">Create Fundraiser</h2>
        </header>
        <form class="my-10" @submit.prevent="handleSubmit">
          <div
            class="flex flex-col my-6 text-text-primary font-semibold text-sm"
          >
            <div class="flex">
              <label>Title</label>
              <BToolTipBtn :details="infoData.title" />
            </div>
            <input
              class="
                border
                rounded
                border-border-primary
                mt-2
                mb-1
                p-4
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              type="text"
              v-model.trim="title"
              maxlength="40"
            />
            <p class="text-xs font-medium text-right" style="color: #fd5f5f">
              {{ 40 - title.length }}
            </p>
            <span
              v-for="error in v$.title.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
          </div>
          <div
            class="flex flex-col my-6 text-text-primary font-semibold text-sm"
          >
            <div class="flex">
              <label>Description</label>
              <BToolTipBtn :details="infoData.desc" />
            </div>
            <!-- <textarea
          class="
            border
            rounded
            border-border-primary
            my-2
            p-4
            text-sm
            font-medium
            focus:outline-none
            input-shadow
          "
          type="text"
          v-model.trim="description"
          maxlength="1000"
        /> -->
            <!-- {{description}} -->
            <vue-editor
              id="input-plan-english-description"
              v-model="description"
              @text-change="onTextChange"
              ref="editor"
              type="text"
              class="
                border
                rounded
                border-border-primary
                my-2
                p-
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              placeholder="Enter notes here..."
              rows="3"
              max-rows="6"
              required
            />
            <p class="text-xs font-medium text-right" style="color: #fd5f5f">
              {{ 2500 - description.length }}
            </p>
            <span
              v-for="error in v$.description.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
          </div>
          <div
            class="flex flex-col my-6 text-text-primary font-semibold text-sm"
          >
            <div class="flex">
              <label>Category</label>
              <BToolTipBtn :details="infoData.category" />
            </div>
            <BModalSelect
              class="my-2"
              :options="categoryList"
              v-model="category"
            />
            <span
              v-for="error in v$.category.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
          </div>
          <div
            class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            v-if="isOneTime"
          >
            <div class="flex">
              <label>Duration</label>
              <BToolTipBtn :details="infoData.duration" />
            </div>
            <BModalSelect
              class="my-2"
              :options="durationList"
              v-model="duration"
            />
            <span
              v-for="error in v$.duration.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
          </div>
          <div
            class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            v-else
          >
            <div class="flex">
              <label>Fundraiser Cycle</label>
              <BToolTipBtn :details="infoData.cycle" />
            </div>
            <BModalSelect class="my-2" :options="cycleList" v-model="cycle" />
          </div>
          <div
            class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            v-if="isOneTime"
          >
            <div class="flex">
              <label>Target Amount</label>
              <BToolTipBtn :details="infoData.target" />
            </div>
            <!-- <input
              class="
                border
                rounded
                border-border-primary
                my-2
                p-4
                text-sm
                font-medium
                focus:outline-none
                input-shadow
              "
              type="number"
              placeholder="0.0"
              v-model.number="targetAmount"
              pattern="\d*"
              inputmode="decimal"
              min="0"
            /> -->
            <!-- {{ targetAmount }} -->
            <BCurrencyInput
              name="targetAmount"
              placeholder="0.00"
              @input="handleAmountInput"
            />
            <span
              v-for="error in v$.targetAmount.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
          </div>
          <div
            class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            v-else
          >
            <div class="flex">
              <label>Cycle Target Amount</label>
              <BToolTipBtn :details="infoData.targetCycle" />
            </div>
            <input
              class="my-6 rounded h-1 border border-border-primary slider"
              type="range"
              :min="100000"
              :max="10000000"
              :step="100000"
              v-model.number="targetAmount"
            />
            <span
              class="
                rounded
                border border-border-primary
                px-4
                py-3.5
                min-w-36
                max-w-min
                font-medium
              "
            >
              ₦{{ targetAmount.toLocaleString() }}
            </span>
          </div>
          <div class="my-6" v-if="!isOneTime">
            <label class="text-xs flex items-center my-3 font-medium">
              <input type="checkbox" v-model="shouldHideCycleAmount" />
              <span class="pl-3"
                >Hide cycle target amount from the public.</span
              >
              <BToolTipBtn :details="infoData.hideCycle" />
            </label>
          </div>
          <button
            class="
              bg-btn-bg-primary
              text-btn-text-primary
              rounded
              w-full
              py-4
              my-4
              font-bold
              text-sm
              xs:text-base
            "
            type="submit"
          >
            Continue
          </button>
        </form>
      </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24 pt-8">
        <div class="px-26 py-4">
          <header class="font-semibold text-lg flex text-text-alternate-1 pr-6">
            <button @click="handlePrev">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto">Create Fundraiser</h2>
          </header>
          <form class="my-10" @submit.prevent="handleSubmit">
            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>Title</label>
                <BToolTipBtn :details="infoData.title" />
              </div>
              <input
                class="
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-4
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                type="text"
                v-model.trim="title"
                maxlength="50"
              />
              <p class="text-xs font-medium text-right" style="color: #fd5f5f">
                {{ 50 - title.length }}
              </p>
              <span
                v-for="error in v$.title.$errors"
                :key="error.$uid"
                class="text-xs text-text-danger"
                >{{ error.$message }}</span
              >
            </div>
            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>{{ isOneTime ? 'Description' : 'About us' }}</label>
                <BToolTipBtn :details="infoData.desc" />
              </div>
              <!-- <textarea
          class="
            border
            rounded
            border-border-primary
            my-2
            p-4
            text-sm
            font-medium
            focus:outline-none
            input-shadow
          "
          type="text"
          v-model.trim="description"
          maxlength="1000"
        /> -->
              <!-- {{description}} -->
              <vue-editor
                id="input-plan-english-description"
                v-model="description"
                @text-change="onTextChange"
                ref="editor"
                type="text"
                class="
                  border
                  rounded
                  border-border-primary
                  my-2
                  p-
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                placeholder="Enter notes here..."
                rows="3"
                max-rows="6"
                required
              />
              <p class="text-xs font-medium text-right" style="color: #fd5f5f">
                {{ 2500 - description.length }}
              </p>
              <span
                v-for="error in v$.description.$errors"
                :key="error.$uid"
                class="text-xs text-text-danger"
                >{{ error.$message }}</span
              >
            </div>
            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>Category</label>
                <BToolTipBtn :details="infoData.category" />
              </div>

              <BSelectInput
                class="my-2"
                :options="categoryList"
                v-model="category"
              />
              <span
                v-for="error in v$.category.$errors"
                :key="error.$uid"
                class="text-xs text-text-danger"
                >{{ error.$message }}</span
              >
            </div>

            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
              v-if="isOneTime"
            >
              <div class="flex">
                <label>Duration </label>
                <BToolTipBtn :details="infoData.duration" />
              </div>

              <BSelectInput
                class="my-2"
                :options="durationList"
                v-model="duration"
              />
              <span
                v-for="error in v$.duration.$errors"
                :key="error.$uid"
                class="text-xs text-text-danger"
                >{{ error.$message }}</span
              >
            </div>
            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
              v-else
            >
              <div class="flex">
                <label>Fundraiser Cycle</label>
                <BToolTipBtn :details="infoData.cycle" />
              </div>
              <BModalSelect class="my-2" :options="cycleList" v-model="cycle" />
            </div>
            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
              v-if="isOneTime"
            >
              <div class="flex">
                <label>Target Amount</label>
                <BToolTipBtn :details="infoData.target" />
              </div>
              <!-- <input
                class="
                  border
                  rounded
                  border-border-primary
                  my-2
                  p-4
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                type="number"
                placeholder="0.0"
                v-model.number="targetAmount"
                pattern="\d*"
                inputmode="decimal"
                min="0"
              /> -->
              <!-- {{ targetAmount }} -->
              <BCurrencyInput
                name="targetAmount"
                placeholder="0.00"
                @input="handleAmountInput"
              />
              <span
                v-for="error in v$.targetAmount.$errors"
                :key="error.$uid"
                class="text-xs text-text-danger"
                >{{ error.$message }}</span
              >
            </div>
            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
              v-else
            >
              <div class="flex">
                <label>Cycle Target Amount</label>
                <BToolTipBtn :details="infoData.targetCycle" />
              </div>
              <input
                class="my-6 rounded h-1 border border-border-primary slider"
                type="range"
                :min="100000"
                :max="10000000"
                :step="100000"
                v-model.number="targetAmount"
              />
              <span
                class="
                  rounded
                  border border-border-primary
                  px-4
                  py-3.5
                  min-w-36
                  max-w-min
                  font-medium
                "
              >
                ₦{{ targetAmount.toLocaleString() }}
              </span>
            </div>
            <div class="my-6" v-if="!isOneTime">
              <label class="text-xs flex items-center my-3 font-medium">
                <input type="checkbox" v-model="shouldHideCycleAmount" />
                <span class="pl-3"
                  >Hide cycle target amount from the public.</span
                >
                <BToolTipBtn :details="infoData.hideCycle" />
              </label>
            </div>
            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                rounded
                w-full
                py-4
                my-4
                font-bold
                text-sm
                xs:text-base
              "
              type="submit"
            >
              Continue
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue3-editor';
import { reactive, toRefs, computed } from 'vue';
import { useAppState } from '@/cmp-functions/appState.js';
import BModalSelect from '@/components/ui/BModalSelect';
import infoData from '@/data/info.js';
import BToolTipBtn from '@/components/ui/BToolTipBtn';
import BSelectInput from '../../../components/ui/BSelectInput.vue';
import BCurrencyInput from '@/components/ui/BCurrencyInput.vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

const durationList = [
  '7 days',
  '14 days',
  '30 days',
  '90 days' /*'180 days', '365 days' */,
];
const cycleList = ['Monthly', 'Quarterly'];
const data = {
  title: '',
  description: '',
  category: '',
};
const oneOffData = { duration: '', targetAmount: '' };
const recurringData = {
  cycle: '',
  shouldHideCycleAmount: false,
  targetAmount: 100000,
};

export default {
  name: 'ProjectDetailsOne',

  emits: ['next', 'prev'],

  props: ['project'],

  components: {
    BModalSelect,
    BToolTipBtn,
    VueEditor,
    BSelectInput,
    BCurrencyInput,
  },
  methods: {
    onTextChange() {
      const quill = this.$refs.editor.quill;
      const len = quill.getLength();
      if (len > 2500) {
        console.log(len);
        quill.deleteText(2500, len);
      }
    },
  },

  setup(props, { emit }) {
    const isOneTime = props.project.fundraiserType !== 'PERMANENT';
    const { categories } = useAppState();

    const initFormInputs = {
      ...data,
      ...(isOneTime ? oneOffData : recurringData),
    };
    const required$ = helpers.withMessage('This field is required', required);
    const formInputs = reactive(
      Object.keys(initFormInputs).reduce(
        (acc, next) => ({
          ...acc,
          [next]:
            props.project[next] !== undefined
              ? props.project[next]
              : initFormInputs[next],
        }),
        {}
      )
    );

    const rules = {
      title: { required$ },
      description: { required$ },
      category: { required$ },
      duration: { required$ },
      targetAmount: { required$ },
    };

    const v$ = useVuelidate(rules, formInputs);

    const handleSubmit = async () => {
      const result = await v$.value.$validate();
      if (!result) {
        document.getElementById('form').scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }
      const categoryId = categories.data.find(
        (c) => c.categoryname === formInputs.category
      )?.id;
      emit('next', { ...formInputs, categoryId });
    };

    const handlePrev = () => {
      emit('prev', formInputs);
    };

    const handleAmountInput = (value) => {
      formInputs.targetAmount = value;
    };

    const categoryList = computed(() => {
      return categories.data.map(({ categoryname }) => categoryname);
    });

    const isFilled = computed(() =>
      Object.keys(initFormInputs).every(
        (key) => formInputs[key] !== null && formInputs[key] !== ''
      )
    );

    return {
      ...toRefs(formInputs),
      isOneTime,
      infoData,
      handleSubmit,
      isFilled,
      handlePrev,
      categoryList,
      durationList,
      cycleList,
      handleAmountInput,
      v$,
    };
  },
};
</script>
