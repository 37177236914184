<template>
  <div>
    <div class="lg:hidden">
      <div class="flex flex-col items-center p-6 pt-14 lg:pt-24">
        <SuccessIcon class="my-4 mx-auto" />
        <h2
          class="
            text-lg
            xs:text-xl
            max-w-sm
            text-text-alternate-1
            font-semibold
            my-4
          "
        >
          Sent For Review
        </h2>
        <p class="text-xs font-medium text-text-primary text-center">
          Your fundraiser will be reviewed by the admin and made public if
          approved in less than 24hrs.
        </p>
        <div class="flex flex-col w-full max-w-sm mt-6">
          <router-link
            class="
              bg-btn-bg-primary
              text-btn-text-primary
              my-2
              xs:my-3
              py-4
              rounded
              text-center text-sm
              xs:text-base
              font-bold
            "
            :to="{ name: 'Overview', params: { slug } }"
          >
            View Fundraiser
          </router-link>
          <button
            class="
              bg-btn-bg-alternate-1
              text-btn-text-alternate-1
              my-2
              xs:my-3
              py-4
              rounded
              text-center text-sm
              xs:text-base
              font-bold
            "
            @click="$emit('next')"
          >
            Promote Fundraiser
          </button>
          <!-- <p class="text-2xs xs:text-xs text-text-primary">
        Promoting a fundraiser gives it more visibility and can increase
        donations.
      </p> -->
        </div>
      </div>
    </div>
    <div class="hidden lg:flex justify-center">
      <div class="b-card mt-24">
        <div class="flex flex-col items-center px-26 p-6 pt-14 lg:pt-8">
          <SuccessIcon class="my-4 mx-auto" />
          <h2
            class="
              text-lg
              xs:text-xl
              max-w-sm
              text-text-alternate-1
              font-semibold
              my-4
            "
          >
            Sent For Review
          </h2>
          <p class="text-xs font-medium text-text-primary text-center">
            Your fundraiser will be reviewed by the admin and made public if
            approved in less than 24hrs.
          </p>
          <div class="flex flex-col w-full max-w-sm mt-6">
            <router-link
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                my-2
                xs:my-3
                py-4
                rounded
                text-center text-sm
                xs:text-base
                font-bold
              "
              :to="{ name: 'Overview', params: { slug } }"
            >
              View Fundraiser
            </router-link>
            <button
              class="
                bg-btn-bg-alternate-1
                text-btn-text-alternate-1
                my-2
                xs:my-3
                py-4
                rounded
                text-center text-sm
                xs:text-base
                font-bold
              "
              @click="$emit('next')"
            >
              Promote Fundraiser
            </button>
            <!-- <p class="text-2xs xs:text-xs text-text-primary">
        Promoting a fundraiser gives it more visibility and can increase
        donations.
      </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'ProjectSubmitted',

  emits: ['next', 'prev'],

  props: ['project'],

  setup(props) {
    const slug = computed(() => {
      const { id, title } = props.project;
      return `${id}-${title
        .trim()
        .toLowerCase()
        .replace(/[^a-z\d\s:]/g, '')
        .replace(/\s+/g, '-')}`;
    });

    return { slug };
  },
};
</script>
